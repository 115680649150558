/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetManyTenantDtoResponseDto {
  data: TenantDto[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface TenantDto {
  id: string;
}

export interface EventDto {
  tags?: Record<string, string>;
  id: string;
  tenant?: TenantDto;
  idempotencyKey?: string;
  description?: string;

  /** @format date-time */
  createdDate: string;

  /** @format date-time */
  savedDate: string;
  type: string;
  json: object;
  ledgerEntries?: LedgerEntryDto[];
}

export interface TransactionDto {
  id: number;
  debit: number;
  credit: number;
  amount: number;
  currency: string;
  account: string;
  acc1?: string;
  acc2?: string;
  acc3?: string;
  acc4?: string;
  acc5?: string;
  acc6?: string;
  ledgerEntry?: LedgerEntryDto;
  tenant?: TenantDto;
}

export interface LedgerEntryDto {
  tags: Record<string, string>;
  id: number;

  /** @format date-time */
  date: string;

  /** @format date-time */
  createdDate: string;
  voided: boolean;
  event?: EventDto;
  description: string;
  transactions?: TransactionDto[];
}

export interface PostingRuleTestResultDto {
  ledgerEntries?: LedgerEntryDto[];
  error?: string;
}

export interface PostingRuleDto {
  id: number;
  tenantId: string;
  eventType: string;
  description: string;
  enabled: boolean;
  code?: string;
  error?: string;
  sampleEvent?: string;
}

export type PostingRule = object;

export interface CreatePostingRuleDto {
  tenantId: string;
  eventType: string;
  description: string;
  enabled: boolean;
  code?: string;
  error?: string;
  sampleEvent?: string;
}

export type UpdatePostingRuleDto = object;

export interface PostingRuleTestDto {
  data: string;
  code?: string;
}

export interface BalanceDto {
  tags: Record<string, string>;
  account: string;
  debit: number;
  credit: number;
  amount: number;
  currency: string;
}

export interface BookBalanceItemDto {
  book: string;
  account: string;
  amount: number;
  currency: string;
}

export interface PostEventResponseDto {
  description: string;
  entries?: LedgerEntryDto[];
}

export interface PostEventDto {
  tags?: Record<string, string>;
  tenantId: string;
  idempotencyKey?: string;
  description?: string;

  /** @format date-time */
  createdDate: string;
  type: string;
  json: object;
  ledgerEntries?: LedgerEntryDto[];
}

export interface CreateManyTenantDtoDto {
  bulk: TenantDto[];
}

export interface InvestorRepayment {
  /** @format date-time */
  date: string;
  principal: number;
  interest: number;
  overdueInterest: number;
  interestFee: number;
  overdueInterestFee: number;
  other: number;
  investorId: string;
}

export interface ShareCommittedEvent {
  currency: string;
  dealProviderId: string;
  dealId: string;
  investorId: string;
  investorRepayments: InvestorRepayment[];
  pricingInfo: object;
}

export type CsvUploadResult = object;

export type FormDataTestDto = object;

export interface ProblemDocument {
  status: number;
  title: string;
  detail?: string;
  instance?: string;
  type?: string;
}

export interface RuleBookControllerFindAllPostingRulesParams {
  pageSize?: number;
  pageIndex?: number;
  tenantId: string;
}

export interface LedgerControllerQuickTagsSearchParams {
  tag: string;
  tenantId: string;
}

export interface LedgerControllerQuickSearchAccountsParams {
  account: string;
  tenantId: string;
}

export interface LedgerControllerQuickSearchBooksParams {
  book: string;
  tenantId: string;
}

export interface LedgerControllerQuickSearchEventTypesParams {
  eventType: string;
  tenantId: string;
}

export interface QueryControllerGetTransactionsParams {
  /** Array of full account names */
  accounts?: string[];

  /** Transaction ID of the record after witch start taking records */
  startId?: number;
  tags?: Record<string, string>;

  /** Array of full event type names */
  eventTypes?: string[];
  eventTags?: Record<string, string>;
  skip?: number;
  take?: number;
  ascendingOrder?: boolean;
  skipEvent?: boolean;

  /** @format date-time */
  startDate?: string;

  /** @format date-time */
  endDate?: string;
  book?: string;

  /** @format date-time */
  startCreateDate?: string;

  /** @format date-time */
  endCreateDate?: string;
  currency?: string;
  skipVoided?: boolean;
  tenantId: string;
}

export interface QueryControllerGetBalanceParams {
  /** Array of full account names */
  accounts?: string[];

  /** Transaction ID of the record after witch start taking records */
  startId?: number;
  tags?: Record<string, string>;

  /** Array of full event type names */
  eventTypes?: string[];
  eventTags?: Record<string, string>;
  groupBy?: string[];

  /** @format date-time */
  startDate?: string;

  /** @format date-time */
  endDate?: string;
  book?: string;

  /** @format date-time */
  startCreateDate?: string;

  /** @format date-time */
  endCreateDate?: string;
  currency?: string;
  skipVoided?: boolean;
  tenantId: string;
}

export interface QueryControllerGetBalanceTextParams {
  /** Array of full account names */
  accounts?: string[];

  /** Transaction ID of the record after witch start taking records */
  startId?: number;
  tags?: Record<string, string>;

  /** Array of full event type names */
  eventTypes?: string[];
  eventTags?: Record<string, string>;
  groupBy?: string[];

  /** @format date-time */
  startDate?: string;

  /** @format date-time */
  endDate?: string;
  book?: string;

  /** @format date-time */
  startCreateDate?: string;

  /** @format date-time */
  endCreateDate?: string;
  currency?: string;
  skipVoided?: boolean;
  tenantId: string;
}

export interface QueryControllerGetLedgerEntriesParams {
  /** Array of full account names */
  accounts?: string[];

  /** Transaction ID of the record after witch start taking records */
  startId?: number;
  tags?: Record<string, string>;

  /** Array of full event type names */
  eventTypes?: string[];
  eventTags?: Record<string, string>;
  skip?: number;
  take?: number;
  ascendingOrder?: boolean;
  skipEvent?: boolean;

  /** @format date-time */
  startDate?: string;

  /** @format date-time */
  endDate?: string;
  book?: string;

  /** @format date-time */
  startCreateDate?: string;

  /** @format date-time */
  endCreateDate?: string;
  currency?: string;
  skipVoided?: boolean;
  tenantId: string;
}

export interface BalanceControllerGetBalanceParams {
  book: string;
  accounts?: string[];
  currency?: string;
  tenantId: string;
}

export interface EventsControllerFindAllParams {
  /** @format date-time */
  minCreationDate?: string;

  /** @format date-time */
  maxCreationDate?: string;
  pageSize?: number;
  pageIndex?: number;
  skipVoided?: boolean;
  json?: string | object;
  tenantId: string;
}

export interface EventsControllerReprocessEventsParams {
  startingEventId?: number;
  tenantId: string;
}

export interface GetOneBaseTenantControllerTenantDtoParams {
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];

  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];

  /**
   * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
   * @min 0
   * @max 1
   */
  cache?: number;
  slug: string;
}

export interface GetManyBaseTenantControllerTenantDtoParams {
  /** Selects resource fields. <a href="https://github.com/nestjsx/crud/wiki/Requests#select" target="_blank">Docs</a> */
  fields?: string[];

  /** Adds search condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#search" target="_blank">Docs</a> */
  s?: string;

  /** Adds filter condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#filter" target="_blank">Docs</a> */
  filter?: string[];

  /** Adds OR condition. <a href="https://github.com/nestjsx/crud/wiki/Requests#or" target="_blank">Docs</a> */
  or?: string[];

  /** Adds sort by field. <a href="https://github.com/nestjsx/crud/wiki/Requests#sort" target="_blank">Docs</a> */
  sort?: string[];

  /** Adds relational resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#join" target="_blank">Docs</a> */
  join?: string[];

  /** Limit amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#limit" target="_blank">Docs</a> */
  limit?: number;

  /** Offset amount of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#offset" target="_blank">Docs</a> */
  offset?: number;

  /** Page portion of resources. <a href="https://github.com/nestjsx/crud/wiki/Requests#page" target="_blank">Docs</a> */
  page?: number;

  /**
   * Reset cache (if was enabled). <a href="https://github.com/nestjsx/crud/wiki/Requests#cache" target="_blank">Docs</a>
   * @min 0
   * @max 1
   */
  cache?: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Ledger API
 * @version 1.0
 * @contact
 *
 * The Ledger API.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags rules
     * @name RuleBookControllerCreatePostingRule
     * @summary Create posting rule
     * @request POST:/api/rules
     * @secure
     */
    ruleBookControllerCreatePostingRule: (data: CreatePostingRuleDto, params: RequestParams = {}) =>
      this.request<PostingRuleDto, any>({
        path: `/api/rules`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns all posting rules
     *
     * @tags rules
     * @name RuleBookControllerFindAllPostingRules
     * @summary Gets all posting rules
     * @request GET:/api/rules/{tenantId}
     * @secure
     */
    ruleBookControllerFindAllPostingRules: (
      { tenantId, ...query }: RuleBookControllerFindAllPostingRulesParams,
      params: RequestParams = {},
    ) =>
      this.request<{ items: PostingRule[]; meta: { pageSize: number; pageIndex: number; totalItems: number } }, any>({
        path: `/api/rules/${tenantId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rules
     * @name RuleBookControllerGetPostingRule
     * @summary Get posting rule by id
     * @request GET:/api/rules/{tenantId}/{id}
     * @secure
     */
    ruleBookControllerGetPostingRule: (tenantId: string, id: number, params: RequestParams = {}) =>
      this.request<PostingRuleDto, any>({
        path: `/api/rules/${tenantId}/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rules
     * @name RuleBookControllerUpdatePostingRule
     * @summary Update posting rule
     * @request PATCH:/api/rules/{tenantId}/{id}
     * @secure
     */
    ruleBookControllerUpdatePostingRule: (
      tenantId: string,
      id: number,
      data: UpdatePostingRuleDto,
      params: RequestParams = {},
    ) =>
      this.request<PostingRuleDto, any>({
        path: `/api/rules/${tenantId}/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rules
     * @name RuleBookControllerRemoveTag
     * @summary Removes a posting rule
     * @request DELETE:/api/rules/{tenantId}/{id}
     * @secure
     */
    ruleBookControllerRemoveTag: (tenantId: string, id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/rules/${tenantId}/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rules
     * @name RuleBookControllerTestPostingRule
     * @summary Test posting rule
     * @request POST:/api/rules/{tenantId}/{id}/test
     * @secure
     */
    ruleBookControllerTestPostingRule: (
      tenantId: string,
      id: number,
      data: PostingRuleTestDto,
      params: RequestParams = {},
    ) =>
      this.request<PostingRuleTestResultDto, any>({
        path: `/api/rules/${tenantId}/${id}/test`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ledger
     * @name LedgerControllerQuickTagsSearch
     * @summary Get list of tag names by search string
     * @request GET:/api/ledger/{tenantId}/transactions/quick-search/tags
     * @secure
     */
    ledgerControllerQuickTagsSearch: (
      { tenantId, ...query }: LedgerControllerQuickTagsSearchParams,
      params: RequestParams = {},
    ) =>
      this.request<string[], any>({
        path: `/api/ledger/${tenantId}/transactions/quick-search/tags`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ledger
     * @name LedgerControllerQuickSearchAccounts
     * @summary Get list of account names by search string
     * @request GET:/api/ledger/{tenantId}/transactions/quick-search/accounts
     * @secure
     */
    ledgerControllerQuickSearchAccounts: (
      { tenantId, ...query }: LedgerControllerQuickSearchAccountsParams,
      params: RequestParams = {},
    ) =>
      this.request<string[], any>({
        path: `/api/ledger/${tenantId}/transactions/quick-search/accounts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ledger
     * @name LedgerControllerQuickSearchBooks
     * @summary Get list of account names by search string
     * @request GET:/api/ledger/{tenantId}/transactions/quick-search/books
     * @secure
     */
    ledgerControllerQuickSearchBooks: (
      { tenantId, ...query }: LedgerControllerQuickSearchBooksParams,
      params: RequestParams = {},
    ) =>
      this.request<string[], any>({
        path: `/api/ledger/${tenantId}/transactions/quick-search/books`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ledger
     * @name LedgerControllerQuickSearchEventTypes
     * @summary Get list of event types by search string
     * @request GET:/api/ledger/{tenantId}/transactions/quick-search/events
     * @secure
     */
    ledgerControllerQuickSearchEventTypes: (
      { tenantId, ...query }: LedgerControllerQuickSearchEventTypesParams,
      params: RequestParams = {},
    ) =>
      this.request<string[], any>({
        path: `/api/ledger/${tenantId}/transactions/quick-search/events`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ledger
     * @name LedgerControllerFindTransactionById
     * @summary Gets transaction by id
     * @request GET:/api/ledger/{tenantId}/transactions/{id}
     * @secure
     */
    ledgerControllerFindTransactionById: (id: number, tenantId: string, params: RequestParams = {}) =>
      this.request<TransactionDto, any>({
        path: `/api/ledger/${tenantId}/transactions/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ledger
     * @name LedgerControllerFindById
     * @summary Gets ledger entry by id
     * @request GET:/api/ledger/{tenantId}/ledger-entries/{id}
     * @secure
     */
    ledgerControllerFindById: (id: number, tenantId: string, params: RequestParams = {}) =>
      this.request<LedgerEntryDto, any>({
        path: `/api/ledger/${tenantId}/ledger-entries/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Search for transactions by filters
     *
     * @tags query
     * @name QueryControllerGetTransactions
     * @summary Query transactions
     * @request GET:/api/query/{tenantId}/transactions
     * @secure
     */
    queryControllerGetTransactions: (
      { tenantId, ...query }: QueryControllerGetTransactionsParams,
      params: RequestParams = {},
    ) =>
      this.request<{ items: TransactionDto[]; meta: { pageSize: number; pageIndex: number; totalItems: number } }, any>(
        {
          path: `/api/query/${tenantId}/transactions`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        },
      ),

    /**
     * @description Search for transactions by filters
     *
     * @tags query
     * @name QueryControllerGetBalance
     * @summary Query balance
     * @request GET:/api/query/{tenantId}/balance
     * @secure
     */
    queryControllerGetBalance: ({ tenantId, ...query }: QueryControllerGetBalanceParams, params: RequestParams = {}) =>
      this.request<BalanceDto[], any>({
        path: `/api/query/${tenantId}/balance`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags query
     * @name QueryControllerGetBalanceText
     * @request GET:/api/query/{tenantId}/balance_text
     * @secure
     */
    queryControllerGetBalanceText: (
      { tenantId, ...query }: QueryControllerGetBalanceTextParams,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/query/${tenantId}/balance_text`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Search for ledger entries by filters
     *
     * @tags query
     * @name QueryControllerGetLedgerEntries
     * @summary Query ledger entries
     * @request GET:/api/query/{tenantId}/ledger-entries
     * @secure
     */
    queryControllerGetLedgerEntries: (
      { tenantId, ...query }: QueryControllerGetLedgerEntriesParams,
      params: RequestParams = {},
    ) =>
      this.request<{ items: LedgerEntryDto[]; meta: { pageSize: number; pageIndex: number; totalItems: number } }, any>(
        {
          path: `/api/query/${tenantId}/ledger-entries`,
          method: "GET",
          query: query,
          secure: true,
          format: "json",
          ...params,
        },
      ),

    /**
     * @description Search for book balances items.
     *
     * @tags balance
     * @name BalanceControllerGetBalance
     * @summary Query book balance
     * @request GET:/api/balance/{tenantId}
     * @secure
     */
    balanceControllerGetBalance: (
      { tenantId, ...query }: BalanceControllerGetBalanceParams,
      params: RequestParams = {},
    ) =>
      this.request<BookBalanceItemDto[], any>({
        path: `/api/balance/${tenantId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Pushes a new event to the ledger, which will record it, and trigger the associated rules
     *
     * @tags events
     * @name EventsControllerPostEvent
     * @summary Post event
     * @request POST:/api/events
     * @secure
     */
    eventsControllerPostEvent: (data: PostEventDto, params: RequestParams = {}) =>
      this.request<PostEventResponseDto, any>({
        path: `/api/events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Pushes new events to the ledger as a transaction, which will record them, and trigger the associated rules
     *
     * @tags events
     * @name EventsControllerPostBulk
     * @summary Post events in bulk
     * @request POST:/api/events/bulk
     * @secure
     */
    eventsControllerPostBulk: (data: PostEventDto[], params: RequestParams = {}) =>
      this.request<PostEventResponseDto[], any>({
        path: `/api/events/bulk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Gets all stored events for given tenant
     *
     * @tags events
     * @name EventsControllerFindAll
     * @summary Get all events for tenant
     * @request GET:/api/events/{tenantId}
     * @secure
     */
    eventsControllerFindAll: ({ tenantId, ...query }: EventsControllerFindAllParams, params: RequestParams = {}) =>
      this.request<{ items: EventDto[]; meta: { pageSize: number; pageIndex: number; totalItems: number } }, any>({
        path: `/api/events/${tenantId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get event by id
     *
     * @tags events
     * @name EventsControllerFindOne
     * @summary Get event by id
     * @request GET:/api/events/{tenantId}/{id}
     * @secure
     */
    eventsControllerFindOne: (tenantId: string, id: string, params: RequestParams = {}) =>
      this.request<EventDto, any>({
        path: `/api/events/${tenantId}/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Deletes all transactions and ledger entries, and reprocesses all events, creating new entries and transactions
     *
     * @tags events
     * @name EventsControllerReprocessEvents
     * @summary Reprocess all events
     * @request POST:/api/events/{tenantId}/reprocess
     * @secure
     */
    eventsControllerReprocessEvents: (
      { tenantId, ...query }: EventsControllerReprocessEventsParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/events/${tenantId}/reprocess`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Deletes stuff related to given deal
     *
     * @tags events
     * @name EventsControllerDeleteDeal
     * @summary delete deal
     * @request DELETE:/api/events/{tenantId}/{providerId}/{dealId}
     * @secure
     */
    eventsControllerDeleteDeal: (tenantId: string, providerId: string, dealId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/events/${tenantId}/${providerId}/${dealId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name GetOneBaseTenantControllerTenantDto
     * @summary Retrieve a single TenantDto
     * @request GET:/api/tenants/{slug}
     * @secure
     */
    getOneBaseTenantControllerTenantDto: (
      { slug, ...query }: GetOneBaseTenantControllerTenantDtoParams,
      params: RequestParams = {},
    ) =>
      this.request<TenantDto, any>({
        path: `/api/tenants/${slug}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name UpdateOneBaseTenantControllerTenantDto
     * @summary Update a single TenantDto
     * @request PATCH:/api/tenants/{slug}
     * @secure
     */
    updateOneBaseTenantControllerTenantDto: (slug: string, data: TenantDto, params: RequestParams = {}) =>
      this.request<TenantDto, any>({
        path: `/api/tenants/${slug}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name ReplaceOneBaseTenantControllerTenantDto
     * @summary Replace a single TenantDto
     * @request PUT:/api/tenants/{slug}
     * @secure
     */
    replaceOneBaseTenantControllerTenantDto: (slug: string, data: TenantDto, params: RequestParams = {}) =>
      this.request<TenantDto, any>({
        path: `/api/tenants/${slug}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name DeleteOneBaseTenantControllerTenantDto
     * @summary Delete a single TenantDto
     * @request DELETE:/api/tenants/{slug}
     * @secure
     */
    deleteOneBaseTenantControllerTenantDto: (slug: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/tenants/${slug}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name GetManyBaseTenantControllerTenantDto
     * @summary Retrieve multiple TenantDtos
     * @request GET:/api/tenants
     * @secure
     */
    getManyBaseTenantControllerTenantDto: (
      query: GetManyBaseTenantControllerTenantDtoParams,
      params: RequestParams = {},
    ) =>
      this.request<GetManyTenantDtoResponseDto | TenantDto[], any>({
        path: `/api/tenants`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name CreateOneBaseTenantControllerTenantDto
     * @summary Create a single TenantDto
     * @request POST:/api/tenants
     * @secure
     */
    createOneBaseTenantControllerTenantDto: (data: TenantDto, params: RequestParams = {}) =>
      this.request<TenantDto, any>({
        path: `/api/tenants`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tenants
     * @name CreateManyBaseTenantControllerTenantDto
     * @summary Create multiple TenantDtos
     * @request POST:/api/tenants/bulk
     * @secure
     */
    createManyBaseTenantControllerTenantDto: (data: CreateManyTenantDtoDto, params: RequestParams = {}) =>
      this.request<TenantDto[], any>({
        path: `/api/tenants/bulk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Posts a share committed event, when an investor commits to a share.
     *
     * @tags marketplace
     * @name MarketplaceControllerPostShareCommittedEvent
     * @summary Posts a share committed event
     * @request POST:/api/marketplace/post-share-committed
     * @secure
     */
    marketplaceControllerPostShareCommittedEvent: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/marketplace/post-share-committed`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags loantrade
     * @name LoantradeControllerUploadEventsText
     * @request POST:/api/loantrade/csv-text
     * @secure
     */
    loantradeControllerUploadEventsText: (params: RequestParams = {}) =>
      this.request<CsvUploadResult, any>({
        path: `/api/loantrade/csv-text`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags loantrade
     * @name LoantradeControllerUploadEvents
     * @request POST:/api/loantrade/csv
     * @secure
     */
    loantradeControllerUploadEvents: (data: FormDataTestDto, params: RequestParams = {}) =>
      this.request<CsvUploadResult, any>({
        path: `/api/loantrade/csv`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
}
