import { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { Field } from 'formik';
import { TenantDto } from 'api';
import { FormDialog, Input, Title } from 'components';
import { notEmpty } from 'utils';
import { useRoles } from 'hooks';

type Props = {
  onSubmit: (arg0: TenantDto) => Promise<void>;
};

const AddTenantForm = () => {
  return (
    <>
      <Title sub name="Tenant details:" />
      <Grid container columnSpacing={3}>
        <Grid item md={6}>
          <Field
            required
            id="id"
            name="id"
            label="Name"
            labelDirection="row"
            component={Input}
            validate={notEmpty}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const AddTenantWidget = ({ onSubmit }: Props) => {
  const { isViewOnly } = useRoles();
  const [isModalOpen, setModalOpen] = useState(false);

  if (isViewOnly) return null;

  return (
    <div>
      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={() => setModalOpen(true)}
        sx={{
          mb: 2,
        }}
      >
        Add a new tenant
      </Button>
      <FormDialog
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={async (values: TenantDto) => {
          try {
            await onSubmit(values);
            setModalOpen(false);
          } catch (e) {
            console.log('Error: ', e);
          }
        }}
      >
        <AddTenantForm />
      </FormDialog>
    </div>
  );
};
