import React, { useState } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { Field } from 'formik';
import { Input, Title, FormDialog, CodeEditor, Checkbox } from 'components';
import { CreatePostingRuleDto } from 'api';
import { notEmpty } from 'utils';
import { useRoles } from 'hooks';

const NewRuleForm = () => {
  return (
    <>
      <Title sub name="Rule details:" />
      <Grid container columnSpacing={3}>
        <Grid item md={6}>
          <Field
            required
            id="eventType"
            name="eventType"
            label="Event type"
            labelDirection="row"
            component={Input}
            validate={notEmpty}
          />
          <Field
            id="enabled"
            name="enabled"
            label="Enabled"
            labelDirection="row"
            component={Checkbox}
            validate={notEmpty}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            required
            id="description"
            name="description"
            label="Description"
            labelDirection="row"
            component={Input}
            validate={notEmpty}
          />
          <Field
            id="error"
            name="error"
            label="Error"
            labelDirection="row"
            component={Input}
          />
        </Grid>
      </Grid>
    </>
  );
};

type Props = {
  onSubmit: (arg0: CreatePostingRuleDto) => Promise<void>;
};

export const NewRuleWidget = ({ onSubmit }: Props) => {
  const { isSuperAdmin } = useRoles();
  const [isModalOpen, setModalOpen] = useState(false);
  const [code, setCode] = useState('');
  const [sampleEvent, setSampleEvent] = useState('');

  if (!isSuperAdmin) return null;

  return (
    <>
      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={() => setModalOpen(true)}
      >
        Add a new rule
      </Button>
      <FormDialog
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        header="Add a new rule"
        initialValues={{
          enabled: false,
        }}
        onSave={async (values: CreatePostingRuleDto) => {
          try {
            await onSubmit({ ...values, code, sampleEvent });
            setModalOpen(false);
          } catch (e) {
            console.log('Error: ', e);
          }
        }}
      >
        <NewRuleForm />
        <Grid container columnSpacing={3}>
          <Grid item md={6}>
            <Typography variant="subtitle1">Code</Typography>
            <CodeEditor
              mode="javascript"
              value={code}
              onChange={(value: string) => setCode(value)}
              name="code-editor"
              minLines={10}
              maxLines={20}
            />
          </Grid>
          <Grid item md={6}>
            <Typography variant="subtitle1">Test event</Typography>
            <CodeEditor
              mode="json"
              value={sampleEvent}
              onChange={(value: string) => setSampleEvent(value)}
              name="event-editor"
              minLines={10}
              maxLines={20}
            />
          </Grid>
        </Grid>
      </FormDialog>
    </>
  );
};
