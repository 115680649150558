import { useState } from 'react';
import { Button } from '@mui/material';
import { useAsyncCallback } from 'react-async-hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useParams } from 'react-router';
import { isEmpty } from 'ramda';

import { Card, TableFilterChangeData } from 'components';
import { useUrlQuery, useRoles } from 'hooks';
import {
  apiClient,
  CreatePostingRuleDto,
  PostingRule,
  RuleBookControllerFindAllPostingRulesParams,
} from 'api';
import { PostingRulesTable } from './PostingRulesTable';
import { NewRuleWidget } from './NewRuleWidget';
import { getNumber } from 'utils/get-number';

type SearchParams = Omit<
  RuleBookControllerFindAllPostingRulesParams,
  'tenantId'
>;

export const RulesPage = () => {
  const { isViewOnly } = useRoles();
  const { pushQuery, replaceQuery, parsedQuery, filters } =
    useUrlQuery<SearchParams>();
  const { tenantId } = useParams<{ tenantId: string }>();
  const [rules, setRules] = useState<PostingRule[]>([]);
  const [rulesCount, setRulesCount] = useState<number>(0);

  const pageSize = getNumber(parsedQuery.pageSize, 10);
  const pageIndex = getNumber(parsedQuery.pageIndex, 0);

  const fetchPostingRules = useAsyncCallback(async (query: SearchParams) => {
    const { data } = await apiClient.api.ruleBookControllerFindAllPostingRules({
      tenantId,
      ...query,
    });

    setRules(data.items);
    setRulesCount(data.meta.totalItems);
  });

  const createRule = useAsyncCallback(async (rule: CreatePostingRuleDto) => {
    await apiClient.api.ruleBookControllerCreatePostingRule(rule);
    await fetchPostingRules.execute({});
  });

  const reprocessEvents = useAsyncCallback(async (tenantId: string) => {
    await apiClient.api.eventsControllerReprocessEvents({tenantId});
    await fetchPostingRules.execute({});
  });

  const handleFiltersChange = async (data: TableFilterChangeData) => {
    pushQuery({
      ...Object.fromEntries(data.filters.map((f) => [f.id, f.value])),
      pageSize: data.pageSize ?? pageSize,
      pageIndex: data.pageIndex ?? pageIndex,
    });
  };

  useDeepCompareEffect(() => {
    if (isEmpty(parsedQuery)) {
      replaceQuery({ pageSize, pageIndex });
    } else {
      fetchPostingRules.execute(parsedQuery);
    }
    // eslint-disable-next-line
  }, [parsedQuery]);

  return (
    <Card>
      <Card.Header title="Posting rules">
        <NewRuleWidget
          onSubmit={(values) => createRule.execute({ ...values, tenantId })}
        />
      </Card.Header>

      <Card.Body loadingContent={reprocessEvents.loading}>
        {!isViewOnly && (
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={() => reprocessEvents.execute(tenantId)}
            sx={{ mb: 2 }}
          >
            Reprocess all events
          </Button>
        )}

        <PostingRulesTable
          postingRules={rules}
          onFiltersChange={handleFiltersChange}
          count={rulesCount}
          initialFilters={filters}
          pageSize={pageSize}
          pageIndex={pageIndex}
        />
      </Card.Body>
    </Card>
  );
};
