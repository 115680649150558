import React, { useCallback, ReactElement } from 'react';
import { FieldProps } from 'formik';
import { FormControlLabel, Checkbox as MaterialCheckbox } from '@mui/material';
import { withFormLabel } from './withFormLabel';

type Props = FieldProps & {
  id?: string;
  title?: string;
  disabled?: boolean;
  onChange: () => void;
};

export const Checkbox = withFormLabel(
  ({
    id,
    field: { name, value = false },
    form: { setFieldValue },
    disabled,
    onChange,
  }: Props): ReactElement => {
    const handleChange = useCallback(() => {
      setFieldValue(name, !value);
    }, [setFieldValue, value, name]);

    return (
      <FormControlLabel
        id={id}
        disabled={disabled}
        onChange={onChange}
        checked={value}
        control={<MaterialCheckbox color="primary" onClick={handleChange} />} // https://github.com/mui-org/material-ui/issues/7261
        label=""
      />
    );
  },
);
