import { Box, colors, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

const useStyles = makeStyles({
  container: {
    display: 'inline-flex',
    border: `4px solid ${colors.grey[500]}`,
    borderRadius: '16px',
    userSelect: 'none',

    '&.clickable': {
      cursor: 'pointer',
    },
  },
  nameBox: {
    padding: '0px 8px',
    backgroundColor: colors.grey[500],
    color: '#fff',
  },
  valueBox: {
    maxWidth: '200px',
    padding: '0px 8px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    fontSize: '12px',
  },

  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-2px',

    '& > div': {
      margin: '2px',
    },
  },
});

type TValue = string | number | boolean | null;

interface Props {
  name: string;
  value?: TValue;
  onClick?: (name: string, value: TValue | undefined) => void;
}

const Tag = ({ name, value, onClick }: Props) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={`${name}: ${value}`}
      placement="top"
      componentsProps={{ tooltip: { className: classes.tooltip } }}
    >
      <Box
        className={clsx(classes.container, { clickable: !!onClick })}
        onClick={() => onClick?.(name, value)}
      >
        <Box className={classes.nameBox}>{name}</Box>
        {value !== undefined && <Box className={classes.valueBox}>{value}</Box>}
      </Box>
    </Tooltip>
  );
};

const Container = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles();

  return <Box className={classes.wrapper}>{children}</Box>;
};

Tag.Container = Container;
export { Tag };
