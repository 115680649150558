import { useEffect, useMemo, useState } from 'react';
import { Box, Button, IconButton, InputLabel, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdClose } from 'react-icons/md';
import { useAsyncDebounce } from 'react-table';
import AsyncSelect from 'react-select/async';

const useInputStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

type GroupName = string;

interface GroupInputContainerProps {
  value: GroupName;
  onChange?: (value: GroupName) => void;
  onDelete?: (value: GroupName) => void;
  loadOptions?: (
    search: string,
  ) => Promise<Array<{ label: string; value: string }>>;
}

const GroupInputContainer = (props: GroupInputContainerProps) => {
  const classes = useInputStyles();
  const [name, setName] = useState(props.value);

  useEffect(() => {
    setName(props.value);
  }, [props.value]);

  const handleChange = () => {
    if (props.value !== name) {
      props.onChange?.(name);
    }
  };

  const debLoadOptions = useAsyncDebounce(
    props.loadOptions ?? (() => {}),
    1000,
  );

  return (
    <Box className={classes.container}>
      {props.loadOptions ? (
        // @ts-ignore
        <AsyncSelect<{ label: string; value: string }, boolean>
          placeholder="Name"
          cacheOptions
          defaultOptions
          value={{ label: name, value: name }}
          onChange={(newValue) => {
            const name = ((newValue as any)?.value) ?? '';
            setName(name);
            props.onChange?.(name);
          }}
          loadOptions={debLoadOptions}
          menuPosition="absolute"
          menuPortalTarget={document.body}
          styles={{
            container: (props) => ({
              ...props,
              width: '100%',
            }),
            menuPortal: (props) => ({
              ...props,
              'z-index': 99999,
            }),
          }}
        />
      ) : (
        <TextField
          required
          fullWidth
          size="small"
          variant="outlined"
          type="text"
          placeholder="Name"
          value={name}
          onChange={({ target }) => setName(target.value)}
          onBlur={handleChange}
        />
      )}

      <IconButton size="small" onClick={() => props.onDelete?.(name)}>
        <MdClose />
      </IconButton>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxHeight: '250px',
    overflow: 'auto',
  },
  label: {
    fontSize: '14px',
  },
}));

interface Props {
  label: string;
  groups: GroupName[];
  onChange?: (tags: GroupName[]) => void;
  loadOptions?: GroupInputContainerProps['loadOptions'];
}

export const GroupSelect = (props: Props) => {
  const classes = useStyles();
  const [withPlaceholder, setWithPlaceholder] = useState(
    props.groups.length === 0,
  );

  const isAddBtnDisabled = useMemo(() => {
    return withPlaceholder || props.groups.some((group) => group?.trim() === '');
  }, [withPlaceholder, props.groups]);

  const handleAdd = () => {
    setWithPlaceholder(true);
  };

  const handleChange = (index: number, value: GroupName) => {
    const res = [...props.groups];
    res[index] = value;
    props.onChange?.(res);
  };

  const handleDelete = (index: number, value: GroupName) => {
    const res = [...props.groups];
    res.splice(index, 1);
    props.onChange?.(res);
  };

  return (
    <Box>
      <InputLabel classes={{ root: classes.label }}>{props.label}</InputLabel>
      <Box className={classes.container}>
        {props.groups.map((tag, index) => (
          <GroupInputContainer
            key={index}
            value={tag}
            onChange={(v) => handleChange(index, v)}
            onDelete={(v) => handleDelete(index, v)}
            loadOptions={props.loadOptions}
          />
        ))}

        {withPlaceholder && (
          <GroupInputContainer
            value={''}
            onChange={(v) => {
              props.onChange?.([...props.groups, v]);
              setWithPlaceholder(false);
            }}
            onDelete={(v) => {
              setWithPlaceholder(false);
            }}
            loadOptions={props.loadOptions}
          />
        )}

        <Button
          variant="contained"
          onClick={() => handleAdd()}
          disabled={isAddBtnDisabled}
        >
          Add new group
        </Button>
      </Box>
    </Box>
  );
};
