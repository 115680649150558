import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Chip,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useAsync } from 'react-async-hook';
import { FiExternalLink } from 'react-icons/fi';

import { apiClient, TransactionDto } from 'api';
import { Card, LinkChip, Tag } from 'components';
import { formatDateTime, formatMoney, moveDecimalPoint } from 'utils';

const useStyles = makeStyles(() => ({
  listItem: {
    padding: '15px 0',
    lineBreak: 'anywhere',
  },
}));

export const TransactionDetailsPage = () => {
  const classes = useStyles();
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();
  const [transaction, setTransaction] = useState<TransactionDto | null>(null);

  const { loading, error } = useAsync(async () => {
    const { data } = await apiClient.api.ledgerControllerFindTransactionById(
      parseInt(id),
      tenantId,
    );

    setTransaction(data);
  }, [id]);

  if (loading) {
    return <CircularProgress size={40} />;
  }

  if (error || !transaction) {
    return <div>Transaction not found</div>;
  }

  return (
    <Card>
      <Card.Header title={`Transaction ${transaction.id ?? ''}`} />
      <Card.Body>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Formik
              enableReinitialize
              initialValues={transaction}
              onSubmit={() => {}}
            >
              {({ values }) => (
                <Form>
                  <Paper elevation={1}>
                    <Typography variant="subtitle1">
                      Transaction details
                    </Typography>
                    <Grid>
                      <Grid>
                        <List>
                          <ListItem
                            divider
                            disableGutters
                            className={classes.listItem}
                          >
                            <Grid container>ID</Grid>
                            <Grid container>{values.id}</Grid>
                          </ListItem>
                          <ListItem
                            divider
                            disableGutters
                            className={classes.listItem}
                          >
                            <Grid container>Account</Grid>
                            <Grid container gap="4px">
                              <LinkChip
                                label={values.account}
                                to={`/tenants/${tenantId}/accounts?accounts[]=${values.account}`}
                                withExternalLinkIcon
                              />
                            </Grid>
                          </ListItem>
                          <ListItem
                            divider
                            disableGutters
                            className={classes.listItem}
                          >
                            <Grid container>Ledger entry</Grid>

                            {values.ledgerEntry && (
                              <Grid container>
                                <Chip
                                  component={Link}
                                  label={values.ledgerEntry.id.toString()}
                                  to={`/tenants/${tenantId}/ledger-entries/${values.ledgerEntry.id}`}
                                  clickable
                                />
                              </Grid>
                            )}
                          </ListItem>
                          <ListItem
                            divider
                            disableGutters
                            className={classes.listItem}
                          >
                            <Grid container>Amount</Grid>
                            <Grid container>
                              {formatMoney(
                                moveDecimalPoint(values.amount, -2),
                                { prefix: `${values.currency} ` },
                              )}
                            </Grid>
                          </ListItem>
                          <ListItem
                            divider
                            disableGutters
                            className={classes.listItem}
                          >
                            <Grid container>Details</Grid>
                            <Grid container>
                              {values.ledgerEntry?.description}
                            </Grid>
                          </ListItem>
                          <ListItem
                            divider
                            disableGutters
                            className={classes.listItem}
                          >
                            <Grid container>Created date</Grid>

                            {values.ledgerEntry &&  (<Grid container>
                            {formatDateTime(values.ledgerEntry.createdDate)}
                            </Grid>)
}
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </Paper>
                </Form>
              )}
            </Formik>
          </Grid>

          <Grid item xs={6}>
            {transaction.ledgerEntry && (
              <Tag.Container>
                {Object.entries(transaction.ledgerEntry.tags).map(
                  ([key, value]) => (
                    <Tag key={key} name={key} value={value} />
                  ),
                )}
              </Tag.Container>
            )}
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
};
