import React, {
  PropsWithChildren,
  ReactElement,
  MouseEventHandler,
} from 'react';
import {
  useTable,
  useFilters,
  usePagination,
  FilterValue,
  Row,
  TableOptions,
  TableInstance,
  useAsyncDebounce,
} from 'react-table';
import { useVirtual } from 'react-virtual';
import { makeStyles } from '@mui/styles';
import {
  TableContainer,
  TablePagination,
  Table as MUTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { ToggleButton } from './ToggleButton';
import UTCDatePicker from './UTCDatePicker';
import { FilterFragment } from 'types';

export interface TableFilterChangeData {
  filters: FilterFragment[];
  pageSize?: number;
  pageIndex?: number;
}

export type TableFilterChangeFn = (data: TableFilterChangeData) => void;

export type TableInitialFilters = FilterFragment[];

export type TableQuickFilters = {
  id: string;
  values: Array<{ label: string; value: string }>;
};

export interface TableProperties<T extends Record<string, unknown>>
  extends TableOptions<T> {
  name: string;
  onFiltersChange: TableFilterChangeFn;
  initialFilters: TableInitialFilters;
  quickFilters?: TableQuickFilters;
  onAdd?: (instance: TableInstance<T>) => MouseEventHandler;
  onDelete?: (instance: TableInstance<T>) => MouseEventHandler;
  onEdit?: (instance: TableInstance<T>) => MouseEventHandler;
  onClick?: (row: Row<T>) => void;
  hiddenColumns?: Array<string>;
  count?: number;
  pageSize?: number;
  pageIndex?: number;
  withPagination?: boolean;
  /** will activate virtualized render for a table */
  containerHeight?: string | number;
}

interface IColumnFilterProps {
  column: {
    filterValue?: unknown;
    setFilter: (
      updater: ((filterValue: FilterValue) => FilterValue) | FilterValue,
    ) => void;
  };
  setFilter: (columnId: string, filterValue: FilterValue) => void;
  filters?: string[];
  state: {
    filters: Array<FilterFragment>;
  };
}

export function DefaultColumnFilter({
  column: { filterValue, setFilter },
}: IColumnFilterProps) {
  return (
    <TextField
      size={'small'}
      value={filterValue ?? ''}
      onChange={(e) => {
        setFilter(e.target.value ?? undefined);
      }}
      variant={'outlined'}
      placeholder={`Search records...`}
    />
  );
}

export function SelectColumnFilter({
  column: { filterValue = [], setFilter },
  filters,
}: IColumnFilterProps) {
  return (
    <Select
      value={filterValue}
      multiple
      onChange={(e) => {
        setFilter(e.target.value);
      }}
    >
      {filters?.map((e) => (
        <MenuItem key={e} value={e}>
          {e}
        </MenuItem>
      ))}
    </Select>
  );
}

interface RangeProps {
  leftId: string;
  rightId: string;
}

export function RangeColumnFilter({
  leftId,
  rightId,
  setFilter,
  state,
}: IColumnFilterProps & RangeProps) {
  const minFilterValue = state?.filters.find(
    (e: { id: string }) => e.id === leftId,
  )?.value;
  const maxFilterValue = state?.filters.find(
    (e: { id: string }) => e.id === rightId,
  )?.value;

  return (
    <Grid container direction="row" wrap="nowrap">
      <TextField
        size="small"
        variant="outlined"
        value={minFilterValue}
        onChange={(e) => {
          setFilter(leftId, e.target.value);
        }}
      />
      <TextField
        size="small"
        variant="outlined"
        value={maxFilterValue}
        onChange={(e) => {
          setFilter(rightId, e.target.value);
        }}
      />
    </Grid>
  );
}

export function DateColumnFilter({
  leftId,
  rightId,
  setFilter,
  state,
}: IColumnFilterProps & RangeProps) {
  const minFilterValue =
    (state?.filters.find((e: { id: string }) => e.id === leftId)
      ?.value as string) ?? null;
  const maxFilterValue =
    (state?.filters.find((e: { id: string }) => e.id === rightId)
      ?.value as string) ?? null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <UTCDatePicker
          key={leftId}
          value={minFilterValue ? dayjs(minFilterValue) : null}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (dayjs(e.target.value).isValid() || !e.target.value) {
              setFilter(leftId, e.target.value);
            }
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <UTCDatePicker
          key={rightId}
          value={maxFilterValue ? dayjs(maxFilterValue) : null}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (dayjs(e.target.value).isValid() || !e.target.value) {
              setFilter(rightId, e.target.value);
            }
          }}
        />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  quickFilters: {
    display: 'flex',
    '& > *:not(:first-child)': {
      marginLeft: '12px',
    },
  },

  noRowsMessage: {},
}));

export function Table<T extends Record<string, unknown>>(
  props: PropsWithChildren<TableProperties<T>>,
): ReactElement {
  const {
    initialFilters,
    pageSize,
    pageIndex,
    columns,
    data,
    count = data.length,
    onFiltersChange,
    quickFilters,
    hiddenColumns = [],
    withPagination = true,
    containerHeight,
  } = props;

  const filters = initialFilters?.filter(
    ({ id }) => id !== 'pageSize' && id !== 'pageIndex',
  );

  const classes = useStyles();

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    gotoPage,
    setPageSize,
    setFilter,
    setAllFilters,
  } = useTable<T>(
    {
      columns,
      data,
      useControlledState: (state: any) => {
        return React.useMemo(
          () => ({
            ...state,
            pageCount: count / state.pageSize,
          }),
          [state],
        );
      },
      initialState: {
        pageSize,
        pageIndex,
        filters,
        hiddenColumns,
      },
      defaultColumn,
      manualPagination: true,
      manualFilters: true,
      pageCount: Math.ceil(count / 10),
    },
    useFilters,
    usePagination,
  );

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const onFiltersChangeDebounced = useAsyncDebounce(onFiltersChange, 500);

  useDeepCompareEffect(() => {
    onFiltersChangeDebounced({
      filters: state.filters,
      pageSize: state.pageSize,
      pageIndex: state.pageIndex,
    });
  }, [state.filters, state.pageSize, state.pageIndex]);

  const getQuickFilterValue = (id: string, value: string): boolean => {
    const filterObj = state.filters.find((el) => el.id === id);
    return !!filterObj?.value.find((el: string) => el === value);
  };

  return (
    <TableContainer ref={tableContainerRef} sx={{ height: containerHeight }}>
      {quickFilters && (
        <>
          <h3>Quick filters</h3>
          <div className={classes.quickFilters}>
            {quickFilters.values.map((el) => {
              return (
                <ToggleButton
                  key={el.value}
                  val={getQuickFilterValue(quickFilters.id, el.value)}
                  onValueChange={(value) => {
                    setFilter(
                      quickFilters.id,
                      !value
                        ? state?.filters
                            ?.find((el) => el.id === quickFilters.id)
                            ?.value?.filter(
                              (value: string) => value !== el.value,
                            )
                        : [
                            ...(state.filters.find(
                              (el) => el.id === quickFilters.id,
                            )?.value ?? []),
                            el.value,
                          ],
                    );
                  }}
                >
                  {el.label}
                </ToggleButton>
              );
            })}
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                setAllFilters([]);
              }}
            >
              Clear filters
            </Button>
          </div>
        </>
      )}
      <MUTable>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key: groupKey, ...restGroupProps } = {
              ...headerGroup.getHeaderGroupProps(),
            };
            return (
              <TableRow key={groupKey} {...restGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key: cellKey, ...restCellProps } = {
                    ...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                      },
                    }),
                  };

                  return (
                    <TableCell key={cellKey} {...restCellProps}>
                      {column.render('Header')}
                      <div>
                        {column.canFilter && column.Filter
                          ? column.render('Filter')
                          : null}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {paddingTop > 0 && (
            <TableRow>
              <TableCell sx={{ height: `${paddingTop}px` }} />
            </TableRow>
          )}

          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];
            prepareRow(row);
            const { key, ...getRowProps } = row.getRowProps();

            return (
              <TableRow key={key} {...getRowProps}>
                {row.cells.map((cell, j) => {
                  const { key, ...getCellProps } = cell.getCellProps({
                    style: {
                      minWidth: cell.column.minWidth,
                      width: cell.column.width,
                      maxWidth: cell.column.maxWidth,
                    },
                  });

                  return (
                    <TableCell key={key} {...getCellProps}>
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}

          {paddingBottom > 0 && (
            <TableRow>
              <TableCell sx={{ height: `${paddingBottom}px` }} />
            </TableRow>
          )}

          {!rows.length && (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className={classes.noRowsMessage}
              >
                <Typography
                  variant="h4"
                  textAlign="center"
                  fontWeight={600}
                  padding={2}
                  color="#ddd"
                >
                  Empty
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MUTable>

      {withPagination && (
        <TablePagination
          component="div"
          count={count}
          page={state.pageIndex}
          onPageChange={(event, page) => gotoPage(page)}
          rowsPerPage={state.pageSize}
          onRowsPerPageChange={(event) => setPageSize(+event.target.value)}
        />
      )}
    </TableContainer>
  );
}
