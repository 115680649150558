import React from 'react';
import { TextFieldProps } from '@mui/material';
import { FieldProps } from 'formik';
import NumericInput from 'material-ui-numeric-input';

import { withFormLabel } from './withFormLabel';

type Props = FieldProps &
  Omit<TextFieldProps, 'name' | 'value' | 'error' | 'type'> & {
    error?: boolean;
  };

export const DecimalInput = withFormLabel(
  ({
    disabled,
    multiline,
    field,
    error = false,
    ...props
  }: Props): JSX.Element => {
    return (
      <NumericInput
        multiline={multiline}
        disabled={disabled}
        error={error}
        size="small"
        variant="outlined"
        precision={2}
        decimalChar=","
        thousandChar="."
        defaultValue={((field.value ?? 0) / 100).toFixed(2)}
        onChange={(event) => {
          if (typeof event.target.value === 'number') {
            props.form.setFieldValue(
              field.name,
              +(event.target.value * 100).toFixed(2),
            );
          }
        }}
      />
    );
  },
);
