import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { useAsync, useAsyncCallback } from 'react-async-hook';

import { apiClient, EventDto, PostEventDto } from 'api';
import { Title, Input, CodeEditor } from 'components';
import { TransactionsTable } from 'pages/TransactionsPage/TransactionsTable';
import { useEffect, useMemo, useState } from 'react';
import { Event } from 'react-toastify/dist/core';
import { lastIndexOf } from 'ramda';

const useStyles = makeStyles(() => ({
  paper: {
    height: '100%',
  },
  block: {
    position: 'relative',
  },
}));

export const EventDetailsPage = () => {
  const classes = useStyles();
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();
  const [editorJson, setEditorJson] = useState<string>('');

  const {
    result: event = {} as EventDto,
    loading,
    error,
    execute: reloadEvent,
  } = useAsync(async () => {
    const { data } = await apiClient.api.eventsControllerFindOne(tenantId, id);
    setEditorJson(JSON.stringify(event.json, null, '\t'));
    return data;
  }, [id]);
  const transactions = useMemo(
    () =>
      event.ledgerEntries
        ?.map(
          (ledgerEntry) =>
            ledgerEntry?.transactions?.map((t) => {
              t.ledgerEntry = ledgerEntry;
              return t;
            }) ?? [],
        )
        .flat() ?? [],
    [event],
  );

  const getVoidEvent = (): PostEventDto => {
    return {
      tenantId: event.tenant?.id || 'Marketplace',
      createdDate: new Date().toISOString(),
      type: 'Ledger:VoidEvent',
      json: {
        eventId: event.id,
      },
      idempotencyKey: `VoidingEvent_${event.id}`,
    };
  };

  const voidEvent = useAsyncCallback(async () => {
    await apiClient.api.eventsControllerPostEvent(getVoidEvent());
    await reloadEvent();
  });

  const updateEvent = useAsyncCallback(async () => {
    try {
      const parsedJson = JSON.parse(editorJson);
      await apiClient.api.eventsControllerPostBulk([
        getVoidEvent(),
        {
          tenantId: event.tenant?.id || 'Marketplace',
          createdDate: event.createdDate,
          type: event.type,
          json: parsedJson,
        },
      ]);
      await reloadEvent();
    } catch (e) {
      alert(`Cannot update event: ${e}`);
    }
  });

  const voidedEntriesCount = useMemo(
    () => event.ledgerEntries?.filter((le) => le.voided).length,
    [event, event?.ledgerEntries],
  );

  const initialJson = useMemo(
    () => JSON.stringify(event.json, null, '\t') ?? '',
    [event?.json],
  );
  useEffect(() => {
    if (initialJson && !editorJson) {
      setEditorJson(initialJson);
    }
  }, [editorJson, initialJson]);
  if (loading) {
    return <CircularProgress size={40} />;
  }

  if (error) {
    return <div>Event not found</div>;
  }

  return (
    <>
      <Title name={`Event ${event.id ?? ''}`} />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Formik
            enableReinitialize
            initialValues={{
              ...event,
              createdDate: event.createdDate?.slice(0, 10),
              savedDate: event.savedDate?.slice(0, 10),
            }}
            onSubmit={() => {}}
          >
            {() => (
              <Form>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="subtitle1">Event details</Typography>
                  <Grid container>
                    <Grid item xs={12} className={classes.block}>
                      <List>
                        <ListItem divider disableGutters>
                          <Field
                            disabled
                            id="id"
                            name="id"
                            label="ID"
                            labelDirection="row"
                            component={Input}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            disabled
                            id="type"
                            name="type"
                            label="Type"
                            labelDirection="row"
                            component={Input}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            disabled
                            id="createdDate"
                            name="createdDate"
                            label="Created Date"
                            labelDirection="row"
                            type="date"
                            component={Input}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            disabled
                            id="savedDate"
                            name="savedDate"
                            label="Saved Date"
                            labelDirection="row"
                            type="date"
                            component={Input}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            multiline
                            disabled
                            id="reprocessingError"
                            name="reprocessingError"
                            label="Reprocessing Error"
                            labelDirection="row"
                            component={Input}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '75%',
                              alignItems: 'center',
                            }}
                          >
                            <Box>Voided</Box>
                            <Box sx={{ marginRight: '32px' }}>
                              {voidedEntriesCount
                                ? voidedEntriesCount ===
                                  event.ledgerEntries?.length
                                  ? 'yes'
                                  : 'partially'
                                : 'no'}
                            </Box>
                          </Box>
                          <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => voidEvent.execute()}
                            disabled={
                              voidedEntriesCount === event.ledgerEntries?.length
                            }
                          >
                            Void event
                          </Button>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Paper>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={3}>
            <Typography variant="subtitle1">JSON</Typography>
            <CodeEditor
              mode="json"
              value={editorJson || initialJson}
              name="json-editor"
              minLines={10}
              maxLines={20}
              onChange={setEditorJson}
              debounceChangePeriod={30}
            />
          </Paper>
          {initialJson !== editorJson && voidedEntriesCount !== 0 && (
            <>
              Event can't be edited due to having voided entries. Try to find a
              more recent event.
            </>
          )}
          <br />
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => updateEvent.execute()}
            disabled={initialJson === editorJson || voidedEntriesCount !== 0}
          >
            Submit edited event
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="subtitle1">Transactions</Typography>
            <TransactionsTable
              data={transactions}
              query={{}}
              onFiltersChange={() => {}}
              count={transactions.length}
              withPagination={false}
              hiddenColumns={['ledgerEntry.event.type']}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
