import { Divider, MenuItem, TextField, TextFieldProps } from '@mui/material';
import { FieldProps } from 'formik';
import { ChangeEvent, useCallback } from 'react';

import { withFormLabel } from './withFormLabel';

type Props = FieldProps &
  Omit<TextFieldProps, 'name' | 'value' | 'error'> & {
    error?: boolean;
    primaryOptions?: Array<{ label: string; value: string }>;
    options: Array<{ label: string; value: string; disabled?: boolean }>;
  };

export const Select = withFormLabel(
  ({
    disabled,
    field,
    error = false,
    primaryOptions,
    options,
    placeholder,
    ...props
  }: Props): JSX.Element => {
    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (field?.onChange) field?.onChange(e);
        if (props.onChange) props.onChange(e);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [field?.onChange, props.onChange],
    );

    return (
      <TextField
        select
        disabled={disabled}
        error={error}
        size="small"
        variant="outlined"
        {...field}
        {...props}
        onChange={onChange}
      >
        {placeholder && (
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {primaryOptions?.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
        {primaryOptions && <Divider />}
        {options.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            disabled={item.disabled}
          >
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    );
  },
);
