import { CircularProgress, CircularProgressProps } from '@mui/material';

export function Loader(props: CircularProgressProps) {
  return (
    <CircularProgress
      {...props}
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
      }}
    />
  );
}
