import { ReactNode } from 'react';
import { Chip } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';

interface LinkChipProps {
  label: ReactNode;
  to: LinkProps['to'];
  withExternalLinkIcon?: boolean;
}

export const LinkChip = ({
  label,
  to,
  withExternalLinkIcon,
}: LinkChipProps) => {
  let text = label;

  if (withExternalLinkIcon) {
    text = (
      <>
        {text}
        <FiExternalLink
          style={{ marginLeft: '4px', verticalAlign: 'text-top' }}
        />
      </>
    );
  }

  return <Chip component={Link} label={text} to={to} clickable />;
};
