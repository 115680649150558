import { useEffect, useMemo, useState } from 'react';
import { Box, Button, IconButton, InputLabel, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdClose } from 'react-icons/md';
import { useAsyncDebounce } from 'react-table';
import AsyncSelect from 'react-select/async';

const useInputStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

type Tag = { name: string; value: string };

interface TagInputContainerProps {
  value: Tag;
  onChange?: (value: Tag) => void;
  onDelete?: (value: Tag) => void;
  loadOptions?: (
    search: string,
  ) => Promise<Array<{ label: string; value: string }>>;
}

const TagInputContainer = (props: TagInputContainerProps) => {
  const classes = useInputStyles();
  const [name, setName] = useState(props.value.name);
  const [value, setValue] = useState(props.value.value);

  useEffect(() => {
    setName(props.value.name);
    setValue(props.value.value);
  }, [props.value.name, props.value.value]);

  const handleChange = () => {
    if (props.value.name !== name || props.value.value !== value) {
      props.onChange?.({ name, value });
    }
  };

  const debLoadOptions = useAsyncDebounce(
    props.loadOptions ?? (() => {}),
    1000,
  );

  return (
    <Box className={classes.container}>
      {props.loadOptions ? (
        // @ts-ignore
        <AsyncSelect<{ label: string; value: string }, boolean>
          placeholder="Name"
          cacheOptions
          defaultOptions
          value={{ label: name, value: name }}
          onChange={(newValue) => {
            // @ts-ignore
            setName(newValue.value);
          }}
          loadOptions={debLoadOptions}
          menuPosition="absolute"
          menuPortalTarget={document.body}
          styles={{
            container: (props) => ({
              ...props,
              width: '100%',
            }),
            menuPortal: (props) => ({
              ...props,
              'z-index': 99999,
            }),
          }}
        />
      ) : (
        <TextField
          required
          fullWidth
          size="small"
          variant="outlined"
          type="text"
          placeholder="Name"
          value={name}
          onChange={({ target }) => setName(target.value)}
          onBlur={handleChange}
        />
      )}

      <TextField
        required
        fullWidth
        size="small"
        variant="outlined"
        type="text"
        placeholder="Value"
        value={value}
        onChange={({ target }) => setValue(target.value)}
        onBlur={handleChange}
      />
      <IconButton
        size="small"
        onClick={() => props.onDelete?.({ name, value })}
      >
        <MdClose />
      </IconButton>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    maxHeight: '250px',
    overflow: 'auto',
  },
  label: {
    fontSize: '14px',
  },
}));

interface Props {
  label: string;
  tags: Tag[];
  onChange?: (tags: Tag[]) => void;
  loadOptions?: TagInputContainerProps['loadOptions'];
}

export const TagSelect = (props: Props) => {
  const classes = useStyles();
  const [withPlaceholder, setWithPlaceholder] = useState(
    props.tags.length === 0,
  );

  const isAddBtnDisabled = useMemo(() => {
    return (
      withPlaceholder ||
      props.tags.some((tag) => {
        return tag.name.trim() === '' || (''+tag.value).trim() === '';
      })
    );
  }, [withPlaceholder, props.tags]);

  const handleChange = (index: number, value: Tag) => {
    const res = [...props.tags];
    res[index] = value;
    props.onChange?.(res);
  };

  const handleDelete = (index: number, value: Tag) => {
    const res = [...props.tags];
    res.splice(index, 1);
    props.onChange?.(res);
  };

  return (
    <Box>
      <InputLabel classes={{ root: classes.label }}>{props.label}</InputLabel>
      <Box className={classes.container}>
        {props.tags.map((tag, index) => (
          <TagInputContainer
            key={index}
            value={tag}
            onChange={(v) => handleChange(index, v)}
            onDelete={(v) => handleDelete(index, v)}
            loadOptions={props.loadOptions}
          />
        ))}

        {withPlaceholder && (
          <TagInputContainer
            value={{ name: '', value: '' }}
            onChange={(v) => {
              if ((''+v.name).trim().length && (''+v.value).trim().length) {
                props.onChange?.([...props.tags, v]);
                setWithPlaceholder(false);
              }
            }}
            onDelete={(v) => {
              setWithPlaceholder(false);
            }}
            loadOptions={props.loadOptions}
          />
        )}

        <Button
          variant="contained"
          onClick={() => setWithPlaceholder(true)}
          disabled={isAddBtnDisabled}
        >
          Add new tag
        </Button>
      </Box>
    </Box>
  );
};
