import { FocusEvent, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { FieldProps, useField } from 'formik';
import { TextFieldProps } from '@mui/material';
import { useAsyncDebounce } from 'react-table';

import { withFormLabel } from './withFormLabel';

type Option = { label: string; value: string };

type Props = FieldProps &
  Omit<TextFieldProps, 'name' | 'value' | 'error' | 'onChange'> & {
    multiselect?: boolean;
    error?: boolean;
    preload?: boolean;
    loadOptions: (value: string) => Promise<Option[]>;
    onChange?: (values: MaybeArray<Option> | null) => void;
  };

export const LazySelect = withFormLabel(
  ({
    disabled,
    error,
    loadOptions,
    placeholder,
    preload = true,
    multiselect,
    onChange,
    ...props
  }: Props): JSX.Element => {
    const [field, , { setValue, setTouched }] = useField(props.field.name);

    const onBlur = useCallback(
      (e: FocusEvent<HTMLInputElement>) => {
        if (field.onBlur) field.onBlur(e);
        if (props.onBlur) props.onBlur(e);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [field.onBlur, props.onBlur],
    );

    return (
      // @ts-ignore
      <AsyncSelect<Option, boolean>
        cacheOptions
        defaultOptions={preload}
        isDisabled={disabled}
        isMulti={multiselect}
        value={field.value}
        {...props}
        onChange={(newValue) => {
          setValue(newValue, true);
          // @ts-ignore
          onChange?.(newValue);
        }}
        onBlur={(e) => {
          onBlur(e);
          setTouched(true, true);
        }}
        loadOptions={useAsyncDebounce(loadOptions, 1000)}
      />
    );
  },
);
