import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { useAsync } from 'react-async-hook';

import { apiClient, LedgerEntryDto } from 'api';
import { Title, Input, Chip } from 'components';
import { notEmpty } from 'utils';
import { TransactionsTable } from 'pages/TransactionsPage/TransactionsTable';
import { useMemo } from 'react';

const useStyles = makeStyles(() => ({
  paper: {
    height: '100%',
  },
  input: {
    width: '80%',
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  chip: {
    width: 'calc(80% - 18px)',
    // '& .MuiChip-root': {
    //   width: '100%',
    // },
  },
  block: {
    position: 'relative',
  },
}));

export const LedgerEntryDetailsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();

  const {
    result: ledgerEntry = {} as LedgerEntryDto,
    loading,
    error,
  } = useAsync(async () => {
    const { data } = await apiClient.api.ledgerControllerFindById(
      parseInt(id),
      tenantId,
    );
    return data;
  }, [id]);

  const transactions = useMemo(() =>
    ledgerEntry?.transactions?.map(t => { t.ledgerEntry = ledgerEntry; return t; }) ?? [],
    [ledgerEntry]);

  if (loading) {
    return <CircularProgress size={40} />;
  }
  if (error) {
    return <div>Ledger entry not found</div>;
  }

  return (
    <>
      <Title name={`Ledger entry ${ledgerEntry.id ?? ''}`} />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Formik
            enableReinitialize
            initialValues={{
              ...ledgerEntry,
              date: ledgerEntry.date?.slice(0, 10),
            }}
            onSubmit={() => {}}
          >
            {() => (
              <Form>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="subtitle1">
                    Ledger entry details
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} className={classes.block}>
                      <List>
                        <ListItem divider disableGutters>
                          <Field
                            disabled
                            id="id"
                            name="id"
                            label="ID"
                            labelDirection="row"
                            className={classes.input}
                            component={Input}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            disabled
                            id="event.type"
                            name="event.type"
                            label="Type"
                            labelDirection="row"
                            className={classes.chip}
                            component={Chip}
                            onClick={(value: string) => {
                              if (ledgerEntry.event?.id) {
                                history.push(
                                  `/tenants/${tenantId}/events/${ledgerEntry.event.id}`,
                                );
                              }
                            }}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            disabled
                            id="date"
                            name="date"
                            label="Date"
                            labelDirection="row"
                            type="date"
                            className={classes.input}
                            component={Input}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            disabled
                            id="book"
                            name="book"
                            label="Book"
                            labelDirection="row"
                            className={classes.input}
                            component={Input}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            disabled
                            id="description"
                            name="description"
                            label="Description"
                            labelDirection="row"
                            component={Input}
                            className={classes.input}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            multiline
                            disabled
                            id="error"
                            name="error"
                            label="Error"
                            labelDirection="row"
                            className={classes.input}
                            component={Input}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Paper>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="subtitle1">Transactions</Typography>
            <TransactionsTable
              data={transactions}
              query={{}}
              onFiltersChange={() => {}}
              count={transactions.length}
              withPagination={false}
              hiddenColumns={['ledgerEntry.description', 'ledgerEntry.id', 'ledgerEntry.event.type']}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
