import React, { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Box,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
  IconButton,
  Button,
  Theme,
} from '@mui/material';
import { FaEdit, FaSave, FaWindowClose } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import clsx from 'clsx';
import { apiClient, PostingRuleDto, UpdatePostingRuleDto } from 'api';
import { Title, Input, Checkbox, CodeEditor, Loader } from 'components';
import { notEmpty } from 'utils';
import { useRoles } from 'hooks';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    transition: 'opacity 0.125s',
    '&.loading': {
      opacity: 0.5,
    },
  },
  paper: {
    height: '100%',
  },
  block: {
    position: 'relative',
  },
  iconsBlock: {
    position: 'absolute',
    top: -27,
    right: 1,
    '& > *': {
      padding: 0,
    },
    '& > *:not(:first-child)': {
      margin: '0 0 0 8px',
    },
  },
  editorButtonsBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  editorButton: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
}));

export const RuleDetailsPage = () => {
  const classes = useStyles();
  const { id, tenantId } = useParams<{ id: string; tenantId: string }>();
  const { isSuperAdmin } = useRoles();

  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [code, setCode] = useState('');
  const [sampleEvent, setSampleEvent] = useState('');
  const [testOutput, setTestOutput] = useState('');
  const [testError, setTestError] = useState('');

  const {
    result: postingRule = {} as PostingRuleDto,
    loading,
    error,
    execute: reloadRule,
  } = useAsync(async () => {
    if (id) {
      const { data } = await apiClient.api.ruleBookControllerGetPostingRule(
        tenantId,
        +id.toString(),
      );

      setInitiallyLoaded(true);
      setSampleEvent(data.sampleEvent ?? '');
      setCode(data.code ?? '');
      return data;
    }
    return;
  }, [id]);

  const updateRule = useAsyncCallback(
    async (updateRule: UpdatePostingRuleDto) => {
      if (id) {
        await apiClient.api.ruleBookControllerUpdatePostingRule(
          tenantId,
          +id.toString(),
          updateRule,
        );
        await reloadRule();
      }
    },
  );

  const testRule = useAsyncCallback(async () => {
    if (id) {
      const output = await apiClient.api.ruleBookControllerTestPostingRule(
        tenantId,
        +id.toString(),
        { data: sampleEvent, code },
      );
      setTestOutput(
        JSON.stringify(output.data.ledgerEntries ?? [], null, '\t') ?? '',
      );
      setTestError(output.data.error ?? '');
    }
  });

  const isPageLoading = useMemo(
    () => !initiallyLoaded && loading,
    [initiallyLoaded, loading],
  );

  const isDataLoading = useMemo(
    () => initiallyLoaded && loading,
    [initiallyLoaded, loading],
  );

  if (isPageLoading) {
    return <CircularProgress size={40} />;
  }

  if (error) {
    return <div>Posting rule not found</div>;
  }

  return (
    <Box className={clsx(classes.container, { loading: isDataLoading })}>
      <Title name={`Rule ${postingRule.id ?? ''}`} />
      <Grid container spacing={4} marginBottom={5}>
        <Grid item xs={6}>
          <Formik
            enableReinitialize
            initialValues={postingRule}
            onSubmit={(values) => {
              updateRule.execute(values);
              setIsDisabled(true);
            }}
          >
            {({ handleReset }) => (
              <Form>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="subtitle1">
                    Posting rule details
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} className={classes.block}>
                      <List>
                        <ListItem divider disableGutters>
                          <Field
                            disabled
                            id="id"
                            name="id"
                            label="ID"
                            labelDirection="row"
                            component={Input}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            disabled
                            id="eventType"
                            name="eventType"
                            label="Event type"
                            labelDirection="row"
                            component={Input}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            disabled={isDisabled}
                            required={!isDisabled}
                            id="enabled"
                            name="enabled"
                            label="Enabled"
                            labelDirection="row"
                            component={Checkbox}
                            validate={notEmpty}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            multiline
                            disabled={isDisabled}
                            required={!isDisabled}
                            id="description"
                            name="description"
                            label="Description"
                            labelDirection="row"
                            component={Input}
                            validate={notEmpty}
                          />
                        </ListItem>
                        <ListItem divider disableGutters>
                          <Field
                            multiline
                            disabled={isDisabled}
                            id="error"
                            name="error"
                            label="Error"
                            labelDirection="row"
                            component={Input}
                          />
                        </ListItem>
                      </List>
                      {isSuperAdmin && (
                        <div className={classes.iconsBlock}>
                          {isDisabled && (
                            <IconButton type="button">
                              <FaEdit
                                size="24"
                                onClick={() => setIsDisabled(false)}
                              />
                            </IconButton>
                          )}
                          {!isDisabled && (
                            <IconButton type="submit" color="primary">
                              <FaSave size="24" />
                            </IconButton>
                          )}
                          {!isDisabled && (
                            <IconButton type="button">
                              <FaWindowClose
                                size="24"
                                onClick={() => {
                                  handleReset();
                                  setIsDisabled(true);
                                }}
                              />
                            </IconButton>
                          )}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Code</Typography>
                <CodeEditor
                  mode="javascript"
                  value={code}
                  onChange={(value: string) => setCode(value)}
                  name="code-editor"
                  minLines={10}
                  maxLines={20}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1">Test event</Typography>
                <CodeEditor
                  mode="json"
                  value={sampleEvent}
                  onChange={(value: string) => setSampleEvent(value)}
                  name="event-editor"
                  minLines={10}
                  maxLines={20}
                />
              </Grid>

              <Grid item xs={12}>
                <div className={classes.editorButtonsBlock}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => reloadRule()}
                    className={classes.editorButton}
                  >
                    Reset
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => testRule.execute()}
                    className={classes.editorButton}
                  >
                    Test
                  </Button>
                  <Button
                    size="small"
                    color="success"
                    variant="contained"
                    onClick={() => updateRule.execute({ code, sampleEvent })}
                    className={classes.editorButton}
                    disabled={!isSuperAdmin}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3}>
            <Typography variant="subtitle1">Test output</Typography>
            {testError ? (
              <Typography variant="body2">Error: {testError}</Typography>
            ) : (
              <CodeEditor
                readOnly
                mode="json"
                value={testOutput}
                name="test-output-editor"
                minLines={10}
                maxLines={30}
              />
            )}
          </Paper>
        </Grid>
      </Grid>

      {isDataLoading && <Loader size={60} />}
    </Box>
  );
};
