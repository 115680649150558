const zeros = (n: number) => '0'.repeat(n);

export const moveDecimalPoint = (value: number, n: number) => {
  if (n === 0) return value;
  let [int = '0', frac = '0'] = Math.abs(value).toString().split('.');
  const negative = value < 0;

  if (n > 0) {
    if (n > frac.length) frac += zeros(n - frac.length);
    int += frac.slice(0, n);
    frac = frac.slice(n);
  } else {
    n = n * -1;
    if (n > int.length) int = zeros(n - int.length) + int;
    frac = int.slice(n * -1) + frac;
    int = int.slice(0, n * -1);
  }

  return parseFloat(`${negative ? '-' : ''}${int}.${frac}`);
};
