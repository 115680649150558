import { useHistory } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Box, Chip } from '@mui/material';
import dayjs from 'dayjs';

import {
  DateColumnFilter,
  Table,
  TableFilterChangeFn,
  TableInitialFilters,
  TableQuickFilters,
} from 'components';
import { EventDto } from 'api';

type EventType = { [key: string]: any } & EventDto;

interface EventsTableProps {
  data: EventDto[];
  onFiltersChange: TableFilterChangeFn;
  initialFilters: TableInitialFilters;
  quickFilters?: TableQuickFilters;
  pageSize: number;
  pageIndex: number;
  count: number;
}

export function EventsTable(props: EventsTableProps) {
  const history = useHistory();
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      Filter: undefined,
      Cell: function Cell({ row: { original } }: CellProps<EventType>) {
        return (
          <Chip
            label={original.id}
            onClick={() => history.push(`events/${original.id}`)}
          />
        );
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: '',
      accessor: 'minCreationDate',
    },
    {
      Header: '',
      accessor: 'maxCreationDate',
    },
    {
      Header: 'Created',
      accessor: 'createdDate',
      Cell: function Cell({ value }: CellProps<EventType>) {
        return dayjs(value).format('DD.MM.YYYY HH:mm:ss');
      },
      Filter: function Filter(otherProps: any) {
        return (
          <DateColumnFilter
            {...otherProps}
            leftId="minCreationDate"
            rightId="maxCreationDate"
          />
        );
      },
    },
    {
      Header: 'Saved',
      accessor: 'savedDate',
      Filter: undefined,
      Cell: function Cell({ value }: CellProps<EventType>) {
        return dayjs(value).format('DD.MM.YYYY HH:mm:ss');
      },
    },   {
      Header: 'Json',
      accessor: 'json',
      Cell: function Cell({ row: { original } }: CellProps<EventType>) {
        let jsonString = JSON.stringify(original.json);
        return <Box sx={{wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'break-spaces', minWidth: '250px'}}> {(jsonString.length > 100) ? jsonString.substring(0, 100) + '...' : jsonString}</Box>
      },
    },
  ];

  const hiddenColumns = ['minCreationDate', 'maxCreationDate'];

  return (
    <Table<EventType>
      name="Events Table"
      initialFilters={props.initialFilters}
      pageSize={props.pageSize}
      pageIndex={props.pageIndex}
      onFiltersChange={props.onFiltersChange}
      data={props.data}
      columns={columns}
      count={props.count}
      hiddenColumns={hiddenColumns}
    />
  );
}
