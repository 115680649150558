import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CellProps, Column } from 'react-table';

import { LinkChip, Table, Tag } from 'components';
import { BalanceDto } from 'api';
import { formatMoney, moveDecimalPoint, stringifyQuery } from 'utils';
import type { QueryPayload } from '../AccountsPage';

type AccountType = { [key: string]: any } & BalanceDto;

interface AccountsTableProps {
  data: BalanceDto[];
  query?: QueryPayload;
  onTagClick?: (data: { name: string; value: string }) => void;
}

export function AccountsTable({ data, query, onTagClick }: AccountsTableProps) {
  const { tenantId } = useParams<{ tenantId: string }>();

  const columns = useMemo(
    (): Column<AccountType>[] => [
      {
        id: 'transactionLink',
        Filter: undefined,
        Cell: function Cell({ row: { original } }: CellProps<AccountType>) {
          return (
            <LinkChip
              label="Transactions"
              to={{
                pathname: `/tenants/${tenantId}/transactions`,
                search: stringifyQuery({
                  ...query,
                  accounts: [original.account],
                }),
              }}
              withExternalLinkIcon
            />
          );
        },
        width: '1%',
      },
      {
        Header: 'Account',
        accessor: 'account',
        Filter: undefined,
      },
      {
        Header: 'Balance',
        accessor: 'amount',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<AccountType, number>) {
          return formatMoney(moveDecimalPoint(value, -2));
        },
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        Filter: undefined,
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<AccountType, object>) {
          return (
            <Tag.Container>
              {Object.entries(value).map(([key, value]) => (
                <Tag
                  key={key}
                  name={key}
                  value={value}
                  onClick={(key, value) => {
                    if (value !== undefined && value !== null) {
                      onTagClick?.({ name: key, value: value.toString() });
                    }
                  }}
                />
              ))}
            </Tag.Container>
          );
        },
      },
    ],
    [tenantId, query, onTagClick],
  );

  return (
    <Table<AccountType>
      name="Accounts Table"
      columns={columns}
      data={data}
      initialFilters={[]}
      onFiltersChange={() => {}}
      withPagination={false}
      containerHeight="550px"
    />
  );
}
