import { useAuth0 } from '@auth0/auth0-react';
import { Role } from 'types';

export const useRoles = () => {
  const { user } = useAuth0();
  
  const roles: Array<Role> =
    user?.['https://creditgate24.com/roles'] ?? [];

  return {
    isSuperAdmin: roles.includes(Role.LedgerSuperAdmin),
    isViewOnly: roles.includes(Role.LedgerViewOnly),
  };
};
