import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import {
  RulesPage,
  RuleDetailsPage,
  TenantsPage,
  EventsPage,
  LedgerEntriesPage,
  LedgerEntryDetailsPage,
  TransactionsPage,
  TransactionDetailsPage,
  EventDetailsPage,
  AccountsPage,
} from './pages';
import { TenantLayout } from './components';

const TenantRouter = () => {
  const { path, url } = useRouteMatch();
  return (
    <TenantLayout>
      <Switch>
        <Route exact path={`${path}/rules`}>
          <RulesPage />
        </Route>
        <Route path={`${path}/rules/:id`}>
          <RuleDetailsPage />
        </Route>
        <Route exact path={`${path}/events`}>
          <EventsPage />
        </Route>
        <Route path={`${path}/events/:id`}>
          <EventDetailsPage />
        </Route>
        <Route exact path={`${path}/ledger-entries`}>
          <LedgerEntriesPage />
        </Route>
        <Route path={`${path}/ledger-entries/:id`}>
          <LedgerEntryDetailsPage />
        </Route>
        <Route exact path={`${path}/transactions`}>
          <TransactionsPage />
        </Route>
        <Route path={`${path}/transactions/:id`}>
          <TransactionDetailsPage />
        </Route>
        <Route exact path={`${path}/accounts`}>
          <AccountsPage />
        </Route>
        <Redirect to={`${url}/rules`} />
      </Switch>
    </TenantLayout>
  );
};

function App() {
  return (
    <Switch>
      <Route exact path="/tenants">
        <TenantsPage />
      </Route>
      <Route path="/tenants/:tenantId">
        <TenantRouter />
      </Route>
      {/* REDIRECT TO TENANTS PAGE IF THERE IS NO MATCH */}
      <Redirect to="/tenants" />
    </Switch>
  );
}

export default App;
