import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { parseQuery, stringifyQuery } from 'utils';
import { FilterObject } from 'types';

export const useUrlQuery = <T extends object = FilterObject>() => {
  const history = useHistory();
  const { search } = useLocation();

  const { parsedQuery, filters } = useMemo(() => {
    const parsedQuery = parseQuery<T>(search);
    const filters = Object.entries(parsedQuery).map(([id, value]) => {
      return { id, value };
    });

    return { parsedQuery, filters };
  }, [search]);

  const pushQuery = useCallback(
    (data: T) => {
      history.push({
        ...history,
        search: stringifyQuery(data),
      });
    },
    [history],
  );

  const replaceQuery = useCallback(
    (data: T) => {
      history.replace({
        ...history,
        search: stringifyQuery(data),
      });
    },
    [history],
  );

  return {
    parsedQuery,
    filters,
    pushQuery,
    replaceQuery,
  };
};
