import { useHistory, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Box, Chip, Tooltip } from '@mui/material';
import dayjs from 'dayjs';

import {
  LinkChip,
  Table,
  TableFilterChangeFn,
  TableInitialFilters,
  TableQuickFilters,
  Tag,
} from 'components';
import { LedgerEntryDto } from 'api';

type LedgerEntryType = { [key: string]: any } & LedgerEntryDto;

interface LedgerEntriesTableProps {
  data: LedgerEntryDto[];
  onFiltersChange: TableFilterChangeFn;
  initialFilters: TableInitialFilters;
  quickFilters?: TableQuickFilters;
  onTagClick?: (data: { name: string; value: string }) => void;
  pageSize: number;
  pageIndex: number;
  count: number;
}

export function LedgerEntriesTable(props: LedgerEntriesTableProps) {
  const { tenantId } = useParams<{ tenantId: string }>();

  const history = useHistory();
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      Filter: undefined,
      Cell: function Cell({ row: { original } }: CellProps<LedgerEntryType>) {
        return (
          <Chip
            label={original.id}
            onClick={() =>
              history.push(`/tenants/${tenantId}/ledger-entries/${original.id}`)
            }
          />
        );
      },
    },
    {
      Header: 'Event',
      accessor: 'event.type',
      Filter: undefined,
      Cell: function Cell({
        value,
        row: { original },
      }: CellProps<LedgerEntryType, string>) {
        return (
          <LinkChip
            label={value}
            to={`/tenants/${tenantId}/events/${original.event?.id}`}
          />
        );
      },
    },
    {
      Header: 'Currency',
      accessor: 'transactions[0].currency',
      Filter: undefined,
    },
    {
      Header: '',
      accessor: 'minCreationDate',
    },
    {
      Header: '',
      accessor: 'maxCreationDate',
    },
    {
      Header: 'Date',
      accessor: 'date',
      Filter: undefined,
      Cell: function Cell({ value }: CellProps<LedgerEntryType, string>) {
        return dayjs.utc(value).format('DD.MM.YYYY');
      },
    },
    {
      Header: 'Created',
      accessor: 'createdDate',
      Filter: undefined,
      Cell: function Cell({ value }: CellProps<LedgerEntryType, string>) {
        return dayjs.utc(value).format('DD.MM.YYYY');
      },
    },
    {
      Header: 'Book',
      accessor: 'book',
      Filter: undefined,
      Cell: function Cell({ value }: CellProps<LedgerEntryType, string>) {
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                display: 'inline-block',
                width: '200px',
                overflow: 'hidden',
                // whiteSpace: 'nowrap',
                overflowWrap: 'break-word',
                textOverflow: 'ellipsis',
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },},
    {
      Header: 'Description',
      accessor: 'description',
      Filter: undefined,
      Cell: function Cell({ value }: CellProps<LedgerEntryType, string>) {
        return (
          <Tooltip title={value}>
            <Box
              sx={{
                display: 'inline-block',
                width: '200px',
                overflow: 'hidden',
                // whiteSpace: 'nowrap',
                overflowWrap: 'break-word',
                textOverflow: 'ellipsis',
              }}
            >
              {value}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      Header: 'Tags',
      accessor: 'tags',
      Filter: undefined,
      Cell: function Cell({ value }: CellProps<LedgerEntryType, object>) {
        return (
          <Tag.Container>
            {Object.entries(value).map(([key, value]) => (
              <Tag
                key={key}
                name={key}
                value={value}
                onClick={() => props.onTagClick?.({ name: key, value })}
              />
            ))}
          </Tag.Container>
        );
      },
    },
    {
      Header: 'Voided',
      accessor: 'voided',
      Filter: undefined,
      Cell: function Cell({ value }: CellProps<LedgerEntryType, string>) {
        return value ? 'yes' : '';
      },
    },
  ];

  const hiddenColumns = ['minCreationDate', 'maxCreationDate'];

  return (
    <Table<LedgerEntryType>
      name="Ledger Entries Table"
      initialFilters={props.initialFilters}
      pageSize={props.pageSize}
      pageIndex={props.pageIndex}
      onFiltersChange={props.onFiltersChange}
      data={props.data}
      columns={columns}
      count={props.count}
      hiddenColumns={hiddenColumns}
    />
  );
}
