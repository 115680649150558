import React from 'react';
import { Chip as MaterialChip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FieldProps } from 'formik';
import { withFormLabel } from './withFormLabel';
import clsx from 'clsx';

type Props = FieldProps & {
  onClick: (arg0: string) => void;
  className: string;
};

const useStyles = makeStyles({
  chipWrapper: {
    width: '200px',
    padding: '0 14px 8px 6px',
  },
});

export const Chip = withFormLabel(
  ({ field, onClick, className }: Props): JSX.Element => {
    const classes = useStyles();
    return (
      <div className={clsx(classes.chipWrapper, className)}>
        <MaterialChip
          label={field.value}
          onClick={() => onClick(field.value)}
        />
      </div>
    );
  },
);
