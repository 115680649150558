import { Dispatch, SetStateAction } from 'react';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { MdOutlineMenu } from 'react-icons/md';

type Props = {
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
};

export const Header = ({ isMenuOpen, setIsMenuOpen }: Props) => (
  <AppBar position="static" color="inherit" elevation={1} sx={{ padding: 0 }}>
    <Toolbar>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <MdOutlineMenu />
      </IconButton>
    </Toolbar>
  </AppBar>
);
