import useDeepCompareEffect from 'use-deep-compare-effect';

import { Card, TableFilterChangeData } from 'components';
import { useUrlQuery } from 'hooks';
import { getNumber } from 'utils';
import { LedgerEntriesTable } from './LedgerEntriesTable';
import { apiClient, LedgerEntryDto } from '../../api';
import { useAsyncCallback } from 'react-async-hook';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { QueryPayload } from '../TransactionsPage/TransactionsPage';
import { AccountsFilters, FilterValues } from '../AccountsPage/AccountsFilters';
import dayjs from 'dayjs';

type Filters = QueryPayload;

const fromQueryToFilter = (data: Filters): FilterValues => {
  return {
    accounts: data.accounts ?? [],
    eventTypes: data.eventTypes ?? [],
    skipVoided: data.skipVoided || undefined,
    book: data.book ?? '',
    currency: data.currency ?? '',
    startDate: data.startDate ?? '',
    endDate: data.endDate ?? '',
    tags: data.tags
      ? Object.fromEntries(
          Object.entries(data.tags).map(([key, value]) => [key, value ?? '']),
        )
      : {},
  };
};

const fromFilterToQuery = (data: FilterValues): Filters => {
  const parseDate = (date: Date | string | undefined) => {
    if (date === undefined) return;
    const res = dayjs(date);
    if (res.isValid()) return res.format('YYYY-MM-DD');
  };

  return {
    accounts: data.accounts.length ? data.accounts : undefined,
    eventTypes: data.eventTypes.length ? data.eventTypes : undefined,
    skipVoided: data.skipVoided || undefined,
    book: data.book || undefined,
    currency: data.currency ? data.currency : undefined,
    startDate: parseDate(data.startDate),
    endDate: parseDate(data.endDate),
    tags: Object.keys(data.tags).length
      ? Object.fromEntries(
          Object.entries(data.tags).map(([key, value]) => [key, value]),
        )
      : undefined,
  };
};

export const LedgerEntriesPage = () => {
  const { parsedQuery, pushQuery, replaceQuery, filters } =
    useUrlQuery<QueryPayload>();

  const { tenantId } = useParams<{ tenantId: string }>();
  const pageSize = getNumber(parsedQuery.take, 10);
  const pageIndex = getNumber(parsedQuery.skip, 0);
  const [ledgerEntries, setLedgerEntries] = useState<LedgerEntryDto[]>([]);
  const [ledgerEntriesCount, setLedgerEntriesCount] = useState<number>(0);

  const fetchLedgerEntries = useAsyncCallback(
    async (query: QueryPayload = {}) => {
      const { data } = await apiClient.api.queryControllerGetLedgerEntries({
        tenantId,
        ...query,
      });
      setLedgerEntries(data?.items ?? []);
      setLedgerEntriesCount(data?.meta?.totalItems ?? 0);
    },
  );
  const handleTagClick = (data: { name: string; value: string }) => {
    const newFilterValues = fromQueryToFilter({ ...parsedQuery });
    newFilterValues.tags = { ...newFilterValues.tags, [data.name]: data.value };

    pushQuery({
      take: parsedQuery.take,
      skip: parsedQuery.skip,
      ...fromFilterToQuery(newFilterValues),
    });
  };

  const handleFiltersChange = (data: FilterValues) => {
    pushQuery({
      take: parsedQuery.take,
      skip: parsedQuery.skip,
      ...fromFilterToQuery(data),
    });
  };

  const onTableFiltersChange = (data: TableFilterChangeData) => {
    const take = data.pageSize ?? pageSize;
    // prettier-ignore
    const skip = data.pageIndex !== undefined
      ? data.pageIndex * take
      : parsedQuery.skip ?? 0;

    pushQuery({ ...parsedQuery, take: take, skip: skip });
  };

  useDeepCompareEffect(() => {
    if (parsedQuery.take === undefined || parsedQuery.skip === undefined) {
      replaceQuery({
        take: pageSize,
        skip: pageIndex,
        skipVoided: true,
        ...parsedQuery,
      });
    } else {
      fetchLedgerEntries.execute(parsedQuery);
    }
    // eslint-disable-next-line
  }, [parsedQuery]);

  return (
    <Card>
      <Card.Header title="Ledger entries" />
      <Card.Body>
        <AccountsFilters
          initialValues={fromQueryToFilter(parsedQuery)}
          onChange={handleFiltersChange}
        />
        <LedgerEntriesTable
          data={ledgerEntries}
          onFiltersChange={onTableFiltersChange}
          onTagClick={handleTagClick}
          count={ledgerEntriesCount}
          initialFilters={filters}
          pageSize={pageSize}
          pageIndex={pageIndex}
        />
      </Card.Body>
    </Card>
  );
};
