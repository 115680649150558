import { formatMoney as fm } from 'accounting';

type BaseConfig = Parameters<typeof fm>[1];

// other cfg options can be found in `accounting` module
type Config = Omit<BaseConfig, 'symbol'> & {
  /** Prefix before formatted number */
  prefix?: string;
  /** Suffix after formatted number */
  suffix?: string;
  /**
   * If the number doesn't have minor units (cents) it will not display it
   * (will not put `.00` at the end)
   */
  optionalDecimals?: boolean;
  /** Will put `+` | `-` before number */
  forceSign?: boolean;
  /** Will put space after sign */
  spaceSign?: boolean;
};

const DEFAULT_CONFIG: Config & { symbol: string } = {
  decimal: '.',
  thousand: '’',
  precision: 2,
  symbol: '',
};

const resolveEndSpace = (withSign: boolean) => (str: string) => {
  return `${str}${withSign ? ' ' : ''}`;
};

export const formatMoney = (value: number, config: Config = {}) => {
  const cfg = Object.assign({ ...config }, DEFAULT_CONFIG);
  let res = fm(value, cfg as BaseConfig);

  if (cfg.optionalDecimals && value % 1 === 0) {
    res = res.slice(0, res.lastIndexOf(cfg.decimal!));
  }

  res = res.replace(/^-/, '');

  const applySpace = resolveEndSpace(!!cfg.spaceSign);
  const sign =
    value > 0 && cfg.forceSign === true
      ? applySpace(`+`)
      : value < 0
      ? applySpace(`-`)
      : '';

  res = `${cfg.prefix ?? ''}${sign}${res}${cfg.suffix ?? ''}`;
  return res;
};
