import { Box, InputLabel, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: '8px',
  },
  group: {
    flexBasis: '50%',
  },
  label: {
    fontSize: '14px',
  },
}));

type Value = { from?: Date | string; to?: Date | string };

interface Props {
  value: Value;
  onChange?: (value: Value) => void;
}

const convertFromDate = (date?: Date | string) => {
  if (date === undefined) return '';
  const res = dayjs(date);
  return res.isValid() ? res.format('YYYY-MM-DD') : '';
};

const convertToDate = (date: string) => {
  return dayjs(date).toDate();
};

export const DateRangePicker = ({ value, onChange }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.group}>
        <InputLabel classes={{ root: classes.label }}>From</InputLabel>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          type="date"
          value={convertFromDate(value.from)}
          onChange={({ target }) => {
            onChange?.({ ...value, from: convertToDate(target.value) });
          }}
        />
      </Box>
      <Box className={classes.group}>
        <InputLabel classes={{ root: classes.label }}>To</InputLabel>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          type="date"
          value={convertFromDate(value.to)}
          onChange={({ target }) => {
            onChange?.({ ...value, to: convertToDate(target.value) });
          }}
        />
      </Box>
    </Box>
  );
};
