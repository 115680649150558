import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { Api } from './client';
import { baseUrl } from 'wrappers/ConfigLoaderWrapper';
import { stringifyQuery } from 'utils';

const apiClient = new Api({
  baseURL: baseUrl,
  paramsSerializer: stringifyQuery,
  securityWorker: (securityData: { token: string } | null) => {
    if (securityData) {
      return {
        headers: {
          Authorization: `Bearer ${securityData.token}`,
        },
      };
    }
  },
});

apiClient.instance.interceptors.response.use(undefined, (error: AxiosError) => {
  const rawErrorMessage = error.response?.data?.detail;
  const errorMessage =
    typeof rawErrorMessage === 'string'
      ? rawErrorMessage
      : Array.isArray(rawErrorMessage)
      ? rawErrorMessage.join(';\n')
      : `Unknown error${error.code ? ` (${error.code})` : ''}`;

  toast.error(errorMessage);
  throw error;
});

export { apiClient };

export * from './client';
