import React, { useMemo } from 'react';
import { Field, Formik, useFormikContext } from 'formik';
import { CSSProperties } from 'styled-components';
import { useAsyncCallback } from 'react-async-hook';
import { useParams } from 'react-router-dom';

import { apiClient } from 'api';
import {
  Checkbox,
  DateRangePicker,
  GroupSelect,
  LazySelect,
  Select,
  TagSelect,
} from 'components';
import Card from 'components/card';
import { Grid } from '@mui/material';
import { Currency } from 'types';

export interface FilterValues {
  accounts: string[];
  eventTypes: string[];
  skipVoided?: boolean;
  tags: Record<string, string>;
  book: string;
  groupBy?: string[];
  currency: string;
  startDate: Date | string;
  endDate: Date | string;
}

type Values = Modify<
  FilterValues,
  {
    accounts: Array<{ label: string; value: string }>;
    book: { label: string; value: string };
    eventTypes: Array<{ label: string; value: string }>;
  }
>;

const transformFiltersToValues = (filters: FilterValues): Values => {
  return {
    ...filters,
    accounts: filters.accounts.map((v) => ({ label: v, value: v })),
    book: { label: filters.book, value: filters.book },
    eventTypes: filters.eventTypes.map((v) => ({ label: v, value: v })),
  };
};

const transformValuesToFilters = (values: Values): FilterValues => {
  return {
    ...values,
    accounts: values.accounts.map(({ value }) => value),
    book: values.book.value,
    eventTypes: values.eventTypes.map(({ value }) => value),
  };
};

interface AccountsFiltersFormProps {
  withGroupBy?: boolean;
}

const AccountsFiltersForm = ({ withGroupBy }: AccountsFiltersFormProps) => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const formik = useFormikContext<Values>();

  const accountSearchVariants = useAsyncCallback(async (search: string) => {
    const { data } = await apiClient.api.ledgerControllerQuickSearchAccounts({
      tenantId: tenantId,
      account: search,
    });
    return data.map((d) => ({ label: d, value: d }));
  });

  const bookSearchVariants = useAsyncCallback(async (search: string) => {
    const { data } = await apiClient.api.ledgerControllerQuickSearchBooks({
      tenantId: tenantId,
      book: search,
    });

    let found;
    const map = data.map((d) => {
      if (d === search) {
        found = true;
        return {};
      }
      return { label: d, value: d };
    });
    return [
      { label: '<none>', value: '' },
      ...(found ? [{ label: search, value: search }] : []),
      ...(search ? [{ label: `%${search}%`, value: `%${search}%` }] : []),
      ...map,
    ];
  });

  const bookRef = null;
  // console.log(bookRef);
  const eventTypesSearchVariants = useAsyncCallback(async (search: string) => {
    const { data } = await apiClient.api.ledgerControllerQuickSearchEventTypes({
      tenantId: tenantId,
      eventType: search,
    });
    return data.map((d) => ({ label: d, value: d }));
  });

  const tagSearchVariants = useAsyncCallback(async (search: string) => {
    const { data } = await apiClient.api.ledgerControllerQuickTagsSearch({
      tenantId: tenantId,
      tag: search,
    });

    return data.map((d) => ({ label: d, value: d }));
  });

  const currencyOptions = useMemo(() => {
    return [
      { label: '<none>', value: '' },
      ...Object.values(Currency).map((item) => ({
        label: item,
        value: item,
      })),
    ];
  }, []);

  const tagList = useMemo(() => {
    return Object.entries(formik.values.tags).map(([name, value]) => ({
      name,
      value,
    }));
  }, [formik.values.tags]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={7}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              id="accounts"
              name="accounts"
              label="Accounts"
              labelDirection="column"
              component={LazySelect}
              multiselect
              loadOptions={accountSearchVariants.execute}
              placeholder={'Select Account name'}
              menuPosition="absolute"
              menuPortalTarget={document.body}
              onChange={() => formik.submitForm()}
              styles={{
                menuPortal: (props: CSSProperties) => ({
                  ...props,
                  'z-index': 99999,
                }),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              id="eventTypes"
              name="eventTypes"
              label="Event types"
              labelDirection="column"
              component={LazySelect}
              multiselect
              loadOptions={eventTypesSearchVariants.execute}
              placeholder={'Select Event type'}
              menuPosition="absolute"
              menuPortalTarget={document.body}
              onChange={() => formik.submitForm()}
              styles={{
                menuPortal: (props: CSSProperties) => ({
                  ...props,
                  'z-index': 99999,
                }),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              id="currency"
              name="currency"
              label="Currency"
              labelDirection="column"
              fullWidth
              component={Select}
              options={currencyOptions}
              onChange={() => formik.submitForm()}
            />
          </Grid>
          {withGroupBy && (
            <Grid item xs={6}>
              <GroupSelect
                groups={formik.values.groupBy ?? []}
                label="Group by"
                onChange={(groups: string[]) => {
                  formik.setFieldValue(
                    'groupBy',
                    groups.length ? groups : undefined,
                  );

                  formik.submitForm();
                }}
                loadOptions={tagSearchVariants.execute}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Field
              id="book"
              name="book"
              label="Book"
              labelDirection="column"
              component={LazySelect}
              loadOptions={bookSearchVariants.execute}
              placeholder={'Select book name'}
              menuPosition="absolute"
              menuPortalTarget={document.body}
              onChange={() => formik.submitForm()}
              styles={{
                menuPortal: (props: CSSProperties) => ({
                  ...props,
                  'z-index': 99999,
                }),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DateRangePicker
              value={{
                from: formik.values.startDate,
                to: formik.values.endDate,
              }}
              onChange={({ from, to }) => {
                formik.setFieldValue('startDate', from ?? '');
                formik.setFieldValue('endDate', to ?? '');
                formik.submitForm();
              }}
            />
          </Grid>
          <Grid item>
            <Field
              id="skipVoided"
              name="skipVoided"
              label="Only not voided"
              labelDirection="row"
              component={Checkbox}
              onChange={() => formik.submitForm()}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={withGroupBy ? 4 : 5}>
        <TagSelect
          label="Tags"
          tags={tagList}
          onChange={(tags) => {
            formik.setFieldValue(
              'tags',
              Object.fromEntries(tags.map((tag) => [tag.name, tag.value])),
            );

            formik.submitForm();
          }}
          loadOptions={tagSearchVariants.execute}
        />
      </Grid>
    </Grid>
  );
};

interface Props {
  initialValues: Partial<FilterValues>;
  onChange(data: FilterValues): void;
  withGroupBy?: boolean;
}

export const AccountsFilters = (props: Props) => {
  return (
    <Card>
      <Card.Body>
        <Formik<Values>
          onSubmit={(values) =>
            props.onChange(transformValuesToFilters(values))
          }
          initialValues={transformFiltersToValues({
            accounts: [],
            eventTypes: [],
            skipVoided: false,
            tags: {},
            currency: '',
            startDate: '',
            endDate: '',
            book: '',
            ...props.initialValues,
          })}
          enableReinitialize
        >
          <AccountsFiltersForm withGroupBy={props.withGroupBy} />
        </Formik>
      </Card.Body>
    </Card>
  );
};
