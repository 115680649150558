import { useCallback, useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useParams } from 'react-router';
import dayjs from 'dayjs';

import { Card } from 'components';
import { useUrlQuery } from 'hooks';
import { apiClient, BalanceDto, QueryControllerGetBalanceParams } from 'api';
import { AccountsTable } from './AccountsTable';
import { AccountsFilters, FilterValues } from './AccountsFilters';

export type QueryPayload = Omit<QueryControllerGetBalanceParams, 'tenantId'>;
type Filters = QueryPayload;

const fromQueryToFilter = (data: Filters): FilterValues => {
  return {
    accounts: data.accounts ?? [],
    eventTypes: data.eventTypes ?? [],
    currency: data.currency ?? '',
    startDate: data.startDate ?? '',
    endDate: data.endDate ?? '',
    skipVoided: data.skipVoided,
    book: data.book ?? '',
    tags: data.tags
      ? Object.fromEntries(
          Object.entries(data.tags).map(([key, value]) => [key, value ?? '']),
        )
      : {},
    groupBy: data.groupBy,
  };
};

const fromFilterToQuery = (data: FilterValues): Filters => {
  const parseDate = (date: Date | string | undefined) => {
    if (date === undefined) return;
    const res = dayjs(date);
    if (res.isValid()) return res.format('YYYY-MM-DD');
  };

  let groupBy = (data.groupBy ?? []).concat(Object.keys(data.tags));
  groupBy = [...new Set(groupBy.concat(Object.keys(data.tags)))].filter(
    (group) => !!group.trim().length,
  );

  return {
    accounts: data.accounts.length ? data.accounts : undefined,
    eventTypes: data.eventTypes.length ? data.eventTypes : undefined,
    currency: data.currency ? data.currency : undefined,
    startDate: parseDate(data.startDate),
    skipVoided: data.skipVoided || undefined,
    book: data.book || undefined,
    endDate: parseDate(data.endDate),
    groupBy: groupBy.length ? groupBy : undefined,
    tags: Object.keys(data.tags).length
      ? Object.fromEntries(
          Object.entries(data.tags).filter(
            ([key, value]) => !!key.trim().length && !!value.trim().length,
          ),
        )
      : undefined,
  };
};

export const AccountsPage = () => {
  const { tenantId } = useParams<{ tenantId: string }>();

  const { parsedQuery, pushQuery } = useUrlQuery<Filters>();
  const filterValues = useMemo(
    () => fromQueryToFilter(parsedQuery),
    [parsedQuery],
  );

  const [accounts, setAccounts] = useState<BalanceDto[]>([]);
  const fetchAccounts = useAsyncCallback(async (query: QueryPayload) => {
    const { data } = await apiClient.api.queryControllerGetBalance({
      tenantId,
      ...query,
    });

    setAccounts(data);
  });

  const handleFiltersChange = (data: FilterValues) => {
    pushQuery(fromFilterToQuery(data));
  };

  const handleTagClick = useCallback(
    (data: { name: string; value: string }) => {
      const newFilterValues = { ...filterValues };
      newFilterValues.tags = { ...filterValues.tags, [data.name]: data.value };
      pushQuery(fromFilterToQuery(newFilterValues));
    },
    [filterValues, pushQuery],
  );

  useDeepCompareEffect(() => {
    fetchAccounts.execute(parsedQuery);
    // eslint-disable-next-line
  }, [parsedQuery]);

  return (
    <>
      <Card>
        <Card.Header title="Accounts" />
        <Card.Body>
          <AccountsFilters
            initialValues={filterValues}
            onChange={handleFiltersChange}
            withGroupBy
          />
          <AccountsTable
            data={accounts}
            query={parsedQuery}
            onTagClick={handleTagClick}
          />
        </Card.Body>
      </Card>
    </>
  );
};
