import React from 'react';
import { Formik, Form } from 'formik';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CgClose } from 'react-icons/cg';

type Props<T> = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (values: T) => void;
  size?: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
  header?: string;
  children: JSX.Element | JSX.Element[];
  initialValues?: Partial<T>;
  cancelBtnName?: string;
  confirmBtnName?: string;
};

const useStyles = makeStyles({
  closeIcon: {
    cursor: 'pointer',
    fontSize: '32px',
    color: 'rgba(0, 0, 0, 0.33)',
  },
});

export const FormDialog = <T,>({
  isOpen,
  onClose,
  onSave,
  size = 'md',
  header,
  children,
  initialValues = {},
  cancelBtnName = 'Cancel',
  confirmBtnName = 'Confirm',
}: Props<T>): JSX.Element => {
  const classes = useStyles();
  return (
    <Dialog fullWidth open={isOpen} onClose={onClose} maxWidth={size}>
      <DialogTitle>
        <div>{header}</div>
        <CgClose className={classes.closeIcon} onClick={() => onClose()} />
      </DialogTitle>
      <DialogContent>
        <Formik onSubmit={onSave} initialValues={initialValues as T}>
          <Form>
            {React.Children.map(children, (item) => item)}
            <DialogActions>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={onClose}
              >
                {cancelBtnName}
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
              >
                {confirmBtnName}
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
