import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Grid,
  Divider,
  Typography,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

interface IBreadcrumb {
  title: string;
  href?: string;
  active?: boolean;
}

interface IProps {
  name: string;
  sub?: boolean;
  breadcrumbs?: IBreadcrumb[];
  children?: React.ReactElement;
}

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'center',
    paddingBottom: '0.5em',
  },
}));

export const Title = ({ sub = false, name, children, breadcrumbs }: IProps) => {
  const classes = useStyles();

  const breadcrumbsComponent = useMemo(() => {
    if (!breadcrumbs) return null;

    return (
      <Box marginLeft="1em">
        <Breadcrumbs>
          {breadcrumbs.map((bc, i) => {
            if (bc.href) {
              return (
                <Link
                  key={bc.href + i}
                  component={RouterLink}
                  to={bc.href}
                  underline="hover"
                  color={bc.active ? 'text.primary' : 'inherit'}
                >
                  {bc.title}
                </Link>
              );
            }

            return (
              <Typography
                key={bc.title + i}
                color={bc.active ? 'text.primary' : 'inherit'}
              >
                {bc.title}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Box>
    );
  }, [breadcrumbs]);

  return (
    <Box marginBottom={sub ? '8px' : '16px'}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.container}
        minHeight={sub ? 'auto' : '48px'}
      >
        <Grid container direction="row" alignItems="center" width="auto">
          <Typography
            gutterBottom
            color="textPrimary"
            variant={sub ? 'subtitle1' : 'h6'}
            marginBottom={0}
          >
            {name}
          </Typography>
          {breadcrumbsComponent}
        </Grid>
        {children}
      </Grid>
      <Divider />
    </Box>
  );
};
