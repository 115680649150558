import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

// Modified to work with Dayjs

/*
 * Beware workarounds involving bugs in material-ui-pickers' design.
 *
 * See https://github.com/mui-org/material-ui-pickers/issues/1358#issuecomment-628015527
 *
 * @material-ui/pickers operate on a Date, but we really want a String.
 * These funky DateUtils let @material-ui/pickers pick dates in the local
 * timezone ... but they ensure outside callers only see ISO8601 Strings.
 */

/**
 * Convert a _local-time_ value to an ISO-8601 Date string.
 *
 * For instance: given 2020-05-13T03:59:50.000Z, if we're in UTC-4,
 * return "2020-05-12".
 *
 * Why? Because material-ui selects dates in local time, not in UTC. If we
 * were to run date.toISOString(), that would convert to UTC and then
 * convert to String; but if we convert to UTC, that changes the date.
 */
function jsDateToLocalISO8601DateString(date: Dayjs | null): string | null {
  if (!date) {
    return null;
  }
  return [
    String(date.year()),
    String(101 + date.month()).substring(1),
    String(100 + date.date()).substring(1),
  ].join('-');
}

function dateStringToLocalDate(s: Dayjs | null): Dayjs | null {
  if (!s) return null;
  return dayjs(s, 'DD.MM.YYYY');
  //   return new DateFnsUtils().parse(s, 'yyyy-MM-dd');
}
type IProps = {
  key: string;
  value: Dayjs | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function UTCDatePicker({ key, value, onChange }: IProps) {
  const handleChange = React.useCallback(
    (date: Dayjs | null) => {
      onChange({
        target: {
          // @ts-ignore
          value: jsDateToLocalISO8601DateString(date),
        },
      });
    },
    [onChange],
  );

  return (
    <MuiDatePicker
      key={key}
      InputProps={{
        fullWidth: true,
        size: 'small',
      }}
      value={dateStringToLocalDate(value)}
      onChange={handleChange}
      mask="__.__.____"
      inputFormat="DD.MM.YYYY"
      renderInput={(params) => <TextField {...params} />}
    />
  );
}
