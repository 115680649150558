import { useAsync, useAsyncCallback } from 'react-async-hook';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { TenantDto, apiClient } from 'api';
import { Layout, Title } from 'components';
import { AddTenantWidget } from './AddTenantWidget';

export const TenantsPage = () => {
  const { result, execute: fetchTenants } = useAsync(
    async () => await apiClient.api.getManyBaseTenantControllerTenantDto({}),
    [],
  );
  const rows = (result?.data as TenantDto[]) ?? [];

  const createTenant = useAsyncCallback(async (tenant: TenantDto) => {
    await apiClient.api.createOneBaseTenantControllerTenantDto(tenant);
    await fetchTenants();
  });

  return (
    <Layout>
      <>
        <Title name="Tenants">
          <AddTenantWidget onSubmit={createTenant.execute} />
        </Title>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Link to={`/tenants/${row.id}`}>{row.id}</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </Layout>
  );
};
