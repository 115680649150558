import { useMemo } from 'react';
import { MenuItem } from 'react-pro-sidebar';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { UrlObject } from 'url';

type Props = {
  children?: React.ReactNode;
  href: string | UrlObject;
  icon?: React.ReactNode;
  activeMatch?: 'full' | 'partial' | 'start';
};

export const ActiveLink = ({
  children,
  href,
  icon,
  activeMatch = 'full',
}: Props) => {
  const match = useRouteMatch(href.toString());

  const active = useMemo(() => {
    switch (activeMatch) {
      case 'full':
        return match?.path === href.toString();
      case 'partial':
        return match?.path.includes(href.toString());
      case 'start':
        return match?.path.startsWith(href.toString());
      default:
        return false;
    }
  }, [href, match?.path, activeMatch]);

  return (
    <li>
      <Link to={href.toString()}>
        <MenuItem icon={icon} active={active}>
          {children}
        </MenuItem>
      </Link>
    </li>
  );
};
