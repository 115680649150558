import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useParams } from 'react-router';
import { isEmpty } from 'ramda';

import { Card, TableFilterChangeData } from 'components';
import { useUrlQuery } from 'hooks';
import { apiClient, EventDto, EventsControllerFindAllParams } from 'api';
import { EventsTable } from './EventsTable';
import { getNumber } from 'utils/get-number';

type SearchPayload = Omit<EventsControllerFindAllParams, 'tenantId'>;

export const EventsPage = () => {
  const { pushQuery, replaceQuery, parsedQuery, filters } =
    useUrlQuery<SearchPayload>();
  const { tenantId } = useParams<{ tenantId: string }>();
  const [events, setEvents] = useState<EventDto[]>([]);
  const [eventsCount, setEventsCount] = useState<number>(0);

  const pageSize = getNumber(parsedQuery.pageSize, 10);
  const pageIndex = getNumber(parsedQuery.pageIndex, 0);

  const fetchEvents = useAsyncCallback(async (query: SearchPayload) => {
    const { data } = await apiClient.api.eventsControllerFindAll({
      tenantId,
      ...query,
    });

    setEvents(data.items);
    setEventsCount(data.meta.totalItems);
  });

  const handleFiltersChange = (data: TableFilterChangeData) => {
    pushQuery({
      ...Object.fromEntries(data.filters.map((f) => [f.id, f.value])),
      pageSize: data.pageSize ?? pageSize,
      pageIndex: data.pageIndex ?? pageIndex,
    });
  };

  useDeepCompareEffect(() => {
    if (isEmpty(parsedQuery)) {
      replaceQuery({ pageSize, pageIndex });
    } else {
      fetchEvents.execute(parsedQuery);
    }
    // eslint-disable-next-line
  }, [parsedQuery]);

  return (
    <Card>
      <Card.Header title="Events" />
      <Card.Body>
        <EventsTable
          data={events}
          onFiltersChange={handleFiltersChange}
          count={eventsCount}
          initialFilters={filters}
          pageSize={pageSize}
          pageIndex={pageIndex}
        />
      </Card.Body>
    </Card>
  );
};
