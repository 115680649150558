import { useHistory } from 'react-router-dom';
import { CellProps } from 'react-table';
import { Chip } from '@mui/material';

import {
  Table,
  TableFilterChangeFn,
  TableInitialFilters,
  TableQuickFilters,
} from 'components';
import { PostingRule } from 'api';

type PostingRuleType = { [key: string]: any } & PostingRule;

interface PostingRulesTableProps {
  postingRules: PostingRule[];
  onFiltersChange: TableFilterChangeFn;
  initialFilters: TableInitialFilters;
  quickFilters?: TableQuickFilters;
  pageSize: number;
  pageIndex: number;
  count: number;
}

export function PostingRulesTable(props: PostingRulesTableProps) {
  const history = useHistory();
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      Filter: undefined,
      Cell: function Cell({ row: { original } }: CellProps<PostingRuleType>) {
        return (
          <Chip
            label={original.id}
            onClick={() => history.push(`rules/${original.id}`)}
          />
        );
      },
    },
    {
      Header: 'Event Type',
      accessor: 'eventType',
      Filter: undefined,
    },
    {
      Header: 'Description',
      accessor: 'description',
      Filter: undefined,
    },
    {
      Header: 'Enabled',
      accessor: 'enabled',
      Filter: undefined,
      Cell: function Cell({ value }: CellProps<PostingRuleType>) {
        return value ? 'Yes' : 'No';
      },
    },
    {
      Header: 'Error',
      accessor: 'error',
      Filter: undefined,
    },
  ];

  return (
    <Table<PostingRuleType>
      name="Posting Rules Table"
      initialFilters={props.initialFilters}
      pageSize={props.pageSize}
      pageIndex={props.pageIndex}
      onFiltersChange={props.onFiltersChange}
      data={props.postingRules}
      columns={columns}
      count={props.count}
    />
  );
}
