import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { Box, Tooltip } from '@mui/material';

import { LinkChip, Table, TableFilterChangeFn, Tag } from 'components';
import { TransactionDto } from 'api';
import { formatMoney, moveDecimalPoint, stringifyQuery } from 'utils';
import { QueryPayload } from '../TransactionsPage';

import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

dayjs.extend(utc);

type TransactionType = { [key: string]: any } & TransactionDto;

interface TransactionTableProps {
  noFilters?: boolean;
  data: TransactionDto[];
  query: QueryPayload;
  onTagClick?: (data: { name: string; value: string }) => void;
  onFiltersChange: TableFilterChangeFn;
  pageSize?: number;
  pageIndex?: number;
  count: number;
  withPagination?: boolean;
  hiddenColumns?: string[];
}

export function TransactionsTable(props: TransactionTableProps) {
  const { tenantId } = useParams<{ tenantId: string }>();

  const columns = useMemo(
    (): Column<TransactionType>[] => [
      {
        Header: 'ID',
        accessor: 'id',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<TransactionType, number>) {
          return (
            <LinkChip
              label={value}
              to={`/tenants/${tenantId}/transactions/${value}`}
            />
          );
        },
      },
      {
        Header: 'Account',
        accessor: 'account',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<TransactionType, string>) {
          const query = { ...props.query };
          delete query.take;
          delete query.skip;

          return (
            <Box display="flex" gap="4px" flexWrap="wrap">
              <LinkChip
                label={value}
                to={{
                  pathname: `/tenants/${tenantId}/accounts`,
                  search: stringifyQuery({ ...query, accounts: [value] }),
                }}
                withExternalLinkIcon
              />
            </Box>
          );
        },
      },
      {
        Header: 'Ledger entry',
        accessor: 'ledgerEntry.id',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<TransactionType, string>) {
          return (
            <LinkChip
              label={value}
              to={`/tenants/${tenantId}/ledger-entries/${value}`}
            />
          );
        },
      },
      {
        Header: 'Event',
        accessor: 'ledgerEntry.event.type',
        Filter: undefined,
        Cell: function Cell({
          value,
          row: { original },
        }: CellProps<TransactionType, string>) {
          return (
            <LinkChip
              label={value}
              to={`/tenants/${tenantId}/events/${original.ledgerEntry?.event?.id}`}
            />
          );
        },
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<TransactionType, number>) {
          return formatMoney(moveDecimalPoint(value, -2));
        },
      },
      {
        Header: 'Currency',
        accessor: 'currency',
        Filter: undefined,
      },
      {
        Header: 'Date',
        accessor: 'ledgerEntry.date',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<TransactionType, string>) {
          return dayjs.utc(value).format('DD.MM.YYYY');
        },
      },
      {
        Header: 'Created',
        accessor: 'ledgerEntry.createdDate',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<TransactionType, string>) {
          return dayjs.utc(value).format('DD.MM.YYYY');
        },
      },
      {
        Header: 'Book',
        accessor: 'ledgerEntry.book',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<TransactionType, string>) {
          return (
            <Tooltip title={value}>
              <Box
                sx={{
                  display: 'inline-block',
                  width: '175px',
                  overflow: 'hidden',
                  // whiteSpace: 'nowrap',
                  overflowWrap: 'break-word',
                  textOverflow: 'ellipsis',
                }}
              >
                {value}
              </Box>
            </Tooltip>
          );
        },},
      {
        Header: 'Description',
        accessor: 'ledgerEntry.description',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<TransactionType, string>) {
          return (
            <Tooltip title={value}>
              <Box
                sx={{
                  display: 'inline-block',
                  width: '175px',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                  textOverflow: 'ellipsis',
                }}
              >
                {value}
              </Box>
            </Tooltip>
          );
        },
      },
      {
        Header: 'Tags',
        accessor: 'ledgerEntry.tags',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<TransactionType, object>) {
          return (
            <Tag.Container>
              {Object.entries(value).map(([key, value]) => (
                <Tag
                  key={key}
                  name={key}
                  value={value}
                  onClick={() => props.onTagClick?.({ name: key, value })}
                />
              ))}
            </Tag.Container>
          );
        },
      },
      {
        Header: 'Voided',
        accessor: 'ledgerEntry.voided',
        Filter: undefined,
        Cell: function Cell({ value }: CellProps<TransactionType, string>) {
          return value ? 'yes' : '';
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tenantId, props.query, props.onTagClick],
  );

  return (
    <Table<TransactionType>
      name="Transaction Table"
      initialFilters={[]}
      onFiltersChange={props.onFiltersChange}
      columns={columns}
      data={props.data}
      count={props.count}
      pageSize={props.pageSize}
      pageIndex={props.pageIndex}
      withPagination={props.withPagination}
      hiddenColumns={[...props.hiddenColumns || [], ...props.query.skipVoided ? ['ledgerEntry.voided', 'voided'] : []]}
    />
  );
}
