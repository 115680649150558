import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from 'react-pro-sidebar';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { FaFileInvoiceDollar } from 'react-icons/fa';
import { CgProfile } from 'react-icons/cg';
import { FcRules } from 'react-icons/fc';
import { MdEvent, MdAccountBalance } from 'react-icons/md';
import { SiHyperledger } from 'react-icons/si';
import { GrTransaction } from 'react-icons/gr';
import { useAuth0 } from '@auth0/auth0-react';

import { ActiveLink } from './ActiveLink';
import 'react-pro-sidebar/dist/css/styles.css';
import { makeStyles } from '@mui/styles';
import { Drawer, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

const useStyles = makeStyles({
  drawerPaper: {
    padding: 0,
    width: '260px',
  },

  title: {
    color: '#fff',
    fontSize: '20px',
    padding: '0 20px',
  },

  proSidebar: {
    height: '100vh',
    width: '260px',
    minWidth: '260px',
    position: 'fixed',
    '& .pro-sidebar-inner': {
      backgroundColor: '#1a4063',
    },

    '& .pro-icon-wrapper': {
      color: 'white',
      background: 'transparent !important',
    },

    '& .pro-menu a': {
      '&::before': {
        position: 'relative',
      },
    },

    '& .active': {
      backgroundColor: '#3a77b0',
    },
  },
});

export const Sidebar = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { user, logout } = useAuth0();
  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      onClose={() => setIsMenuOpen(false)}
      open={isMenuOpen}
      variant={'persistent'}
    >
      <ProSidebar collapsed={false} className={classes.proSidebar}>
        <SidebarHeader>
          <div>
            <Link to="/tenants">
              <p className={classes.title}>Ledger | Admin</p>
            </Link>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <ActiveLink href="/tenants" icon={<FaFileInvoiceDollar />}>
              Tenants
            </ActiveLink>
          </Menu>
        </SidebarContent>
        <Menu iconShape="square">
          <MenuItem
            icon={
              user?.picture ? (
                <img
                  src={user?.picture}
                  alt="User"
                  height="35px"
                  width="35px"
                />
              ) : (
                <CgProfile size="100" />
              )
            }
          >
            <div onClick={() => logout({ returnTo: window.location.origin })}>
              <SidebarFooter>
                <Typography component="div">{user?.name}</Typography>
                <Typography component="div">{user?.email}</Typography>
              </SidebarFooter>
            </div>
          </MenuItem>
        </Menu>
      </ProSidebar>
    </Drawer>
  );
};

export const TenantSidebar = ({
  isMenuOpen,
  setIsMenuOpen,
}: {
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { user, logout } = useAuth0();
  const classes = useStyles();

  const { url } = useRouteMatch();

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      onClose={() => setIsMenuOpen(false)}
      open={isMenuOpen}
      variant={'persistent'}
    >
      <ProSidebar collapsed={false} className={classes.proSidebar}>
        <SidebarHeader>
          <div>
            <Link to="/tenants">
              <p className={classes.title}>Ledger | Admin</p>
            </Link>
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            <ActiveLink href={`${url}/rules`} icon={<FcRules />}>
              Rules
            </ActiveLink>
            <ActiveLink href={`${url}/events`} icon={<MdEvent />}>
              Events
            </ActiveLink>
            <ActiveLink href={`${url}/ledger-entries`} icon={<SiHyperledger />}>
              Ledger Entries
            </ActiveLink>
            <ActiveLink href={`${url}/accounts`} icon={<MdAccountBalance />}>
              Accounts
            </ActiveLink>
            <ActiveLink href={`${url}/transactions`} icon={<GrTransaction />}>
              Transactions
            </ActiveLink>
          </Menu>
        </SidebarContent>
        <Menu iconShape="square">
          <MenuItem
            icon={
              user?.picture ? (
                <img
                  src={user?.picture}
                  alt="User"
                  height="35px"
                  width="35px"
                />
              ) : (
                <CgProfile size="100" />
              )
            }
          >
            <div onClick={() => logout({ returnTo: window.location.origin })}>
              <SidebarFooter>
                <Typography component="div">{user?.name}</Typography>
                <Typography component="div">{user?.email}</Typography>
              </SidebarFooter>
            </div>
          </MenuItem>
        </Menu>
      </ProSidebar>
    </Drawer>
  );
};
