import { useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useParams } from 'react-router';
import dayjs from 'dayjs';

import { Card, TableFilterChangeData } from 'components';
import { useUrlQuery } from 'hooks';
import {
  apiClient,
  QueryControllerGetTransactionsParams,
  TransactionDto,
} from 'api';
import {
  AccountsFilters,
  FilterValues,
} from 'pages/AccountsPage/AccountsFilters';
import { getNumber } from 'utils/get-number';
import { TransactionsTable } from './TransactionsTable';

export type QueryPayload = Omit<
  QueryControllerGetTransactionsParams,
  'tenantId'
>;
type Filters = QueryPayload;

const fromQueryToFilter = (data: Filters): FilterValues => {
  return {
    accounts: data.accounts ?? [],
    eventTypes: data.eventTypes ?? [],
    skipVoided: data.skipVoided || undefined,
    book: data.book ?? '',
    currency: data.currency ?? '',
    startDate: data.startDate ?? '',
    endDate: data.endDate ?? '',
    tags: data.tags
      ? Object.fromEntries(
          Object.entries(data.tags).map(([key, value]) => [key, value ?? '']),
        )
      : {},
  };
};

const fromFilterToQuery = (data: FilterValues): Filters => {
  const parseDate = (date: Date | string | undefined) => {
    if (date === undefined) return;
    const res = dayjs(date);
    if (res.isValid()) return res.format('YYYY-MM-DD');
  };

  return {
    accounts: data.accounts.length ? data.accounts : undefined,
    eventTypes: data.eventTypes.length ? data.eventTypes : undefined,
    skipVoided: data.skipVoided || undefined,
    book: data.book ?? '',
    currency: data.currency ? data.currency : undefined,
    startDate: parseDate(data.startDate),
    endDate: parseDate(data.endDate),
    tags: Object.keys(data.tags).length
      ? Object.fromEntries(
          Object.entries(data.tags).map(([key, value]) => [key, value]),
        )
      : undefined,
  };
};

export const TransactionsPage = () => {
  const { parsedQuery, pushQuery, replaceQuery } = useUrlQuery<QueryPayload>();
  const { tenantId } = useParams<{ tenantId: string }>();

  const [transactions, setTransactions] = useState<TransactionDto[]>([]);
  const [count, setCount] = useState<number>(0);

  const pageSize = getNumber(parsedQuery.take, 10);
  const pageIndex = (parsedQuery.skip ?? 0) / pageSize;

  const fetchTransactions = useAsyncCallback(async (query: QueryPayload) => {
    const { data } = await apiClient.api.queryControllerGetTransactions({
      tenantId,
      ...query,
    });

    setTransactions(data.items);
    setCount(data.meta.totalItems);
  });

  const handleTagClick = (data: { name: string; value: string }) => {
    const newFilterValues = fromQueryToFilter({ ...parsedQuery });
    newFilterValues.tags = { ...newFilterValues.tags, [data.name]: data.value };

    pushQuery({
      take: parsedQuery.take,
      skip: parsedQuery.skip,
      ...fromFilterToQuery(newFilterValues),
    });
  };

  const handleFiltersChange = (data: FilterValues) => {
    pushQuery({
      take: parsedQuery.take,
      skip: parsedQuery.skip,
      ...fromFilterToQuery(data),
    });
  };

  const onTableFiltersChange = (data: TableFilterChangeData) => {
    const take = data.pageSize ?? pageSize;
    // prettier-ignore
    const skip = data.pageIndex !== undefined
      ? data.pageIndex * take
      : parsedQuery.skip ?? 0;

    pushQuery({ ...parsedQuery, take: take, skip: skip });
  };

  useDeepCompareEffect(() => {
    if (parsedQuery.take === undefined || parsedQuery.skip === undefined) {
      replaceQuery({ take: pageSize, skip: pageIndex, skipVoided: true, ...parsedQuery });
    } else {
      fetchTransactions.execute(parsedQuery);
    }
    // eslint-disable-next-line
  }, [parsedQuery]);

  return (
    <Card>
      <Card.Header title="Transactions" />
      <Card.Body>
        <AccountsFilters
          initialValues={fromQueryToFilter(parsedQuery)}
          onChange={handleFiltersChange}
        />

        <TransactionsTable
          data={transactions}
          onTagClick={handleTagClick}
          query={parsedQuery}
          onFiltersChange={onTableFiltersChange}
          count={count}
          pageSize={pageSize}
          pageIndex={pageIndex}
        />
      </Card.Body>
    </Card>
  );
};
